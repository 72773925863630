<template>
  <div id="fraudbarrierpage" class="dashboard" data-testid="dashboard-fraud-barrier-page">
    <div class="mb-2 d-md-none mobile-return-overview-btn">
      <RouterLink to="/mina-tjanster" v-slot="{ href, navigate, isActive }" custom>
        <NdsLink :href="href" :active="isActive" @click="navigate" :prefix="{ prefix: 'far', iconName: 'home-alt' }">
          Översikt
        </NdsLink>
      </RouterLink>
    </div>
    <NdsCardSpacing :gap="3" direction="column" center>
      <NdsCard v-if="!fraudBarrierStatus"
        :heading="{ title: 'Bedrägerispärr', type: 'h3', prefix: { prefix: 'far', iconName: 'shield-alt' } }">
        <template #default>
          <div class="align-items-center justify-content-center d-flex">
            <NdsLoader />
          </div>
        </template>
      </NdsCard>
      <FraudBarrierToggle v-else />
      <NdsCard v-if="!fraudBarrierStatus"
        :heading="{ title: 'Ladda upp polisrapport', type: 'h3', prefix: { prefix: 'far', iconName: 'shield-alt' } }">
        <template #default>
          <div class="align-items-center justify-content-center d-flex">
            <NdsLoader />
          </div>
        </template>
      </NdsCard>
      <NdsCard v-else>
        <template #default>
          <div v-if="fraudBarrierStatus.FraudBarrierStatus === 'APPROVED'" class="fraudbarrier__wrapper">
            <div class="fraudbarrier__wrapper-header fraudbarrier-approved mb-3">
              <p class="mb-0">
                Din polisrapport har blivit godkänd. Du har nu aktiv bedrägerispärr i 5 år
              </p>
            </div>
            <p>
              Kontakta <a href="mailto:utredningen@uc.se" target="_blank">utredningen@uc.se</a> om du vill komplettera
              eller ta bort din polisrapport
            </p>
          </div>
          <div v-else-if="fraudBarrierStatus.FraudBarrierStatus === 'RECEIVED_AWAITING_APPROVAL'"
            class="fraudbarrier__wrapper">
            <div class="fraudbarrier__wrapper-header fraudbarrier-awaitingapproval mb-3">
              <p>Nedan kan du se din uppladdade polisrapport som just nu granskas.</p>
              <p class="mb-0">
                Kontakta <a href="mailto:utredningen@uc.se" target="_blank">utredningen@uc.se</a> om du vill
                komplettera
                eller ta bort din polisrapport
              </p>
            </div>
            <h4>Din uppladdade polisrapport granskas</h4>
            <div class="d-flex align-items-center mt-3">
              <p class="body-small font-weight-bold mb-2">
                <i class="far fa-clock fa-fw mr-2" />Filer granskas
              </p>
            </div>
            <div v-for="(policeReportNames, index) in fraudBarrierStatus.PoliceReportNames" :key="index"
              class="fraudbarrier-review-item position-relative p-3 mb-2">
              <span class="body-small d-block">{{ policeReportNames }}</span>
              <span class="body-xsmall d-block status-label">Granskas</span>
            </div>
          </div>
          <div v-else-if="fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_UNCLEAR' ||
            fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_INCOMPLETE' ||
            fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_OTHER' ||
            fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_WRONG'
          " class="fraudbarrier__wrapper">
            <div v-if="fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_UNCLEAR'"
              class="fraudbarrier__wrapper-header fraudbarrier-rejected mb-4">
              <p class="mb-0">
                Din rapport har tyvärr blivit nekad då vi inte kunnat läsa in filen korrekt.
              </p>
            </div>
            <div v-else-if="fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_INCOMPLETE'"
              class="fraudbarrier__wrapper-header fraudbarrier-rejected mb-4">
              <p class="mb-0">
                Din rapport har tyvärr blivit nekad då din uppladdade fil inte är komplett.
              </p>
            </div>
            <div v-else-if="fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_OTHER'"
              class="fraudbarrier__wrapper-header fraudbarrier-rejected mb-4">
              <p class="mb-0">
                Din uppladdade polisrapport har blivit nekad.
              </p>
            </div>
            <div v-else-if="fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_WRONG'"
              class="fraudbarrier__wrapper-header fraudbarrier-rejected mb-4">
              <p class="mb-0">
                Din rapport har tyvärr blivit nekad då filen du laddat upp inte är en polisrapport.
              </p>
            </div>
            <div v-if="fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_UNCLEAR' ||
              fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_INCOMPLETE' ||
              fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_OTHER' ||
              fraudBarrierStatus.FraudBarrierStatus === 'REJECTED_WRONG'
            " class="mb-4">
              <p>
                För mer info kontakta <a href="mailto:utredningen@uc.se" target="_blank">utredningen@uc.se</a> eller
                ladda upp en ny rapport och försök igen.
              </p>
            </div>
            <h4 class="mb-4">
              Skicka polisrapport
            </h4>
            <DropZone v-if="fraudBarrierStatus.PoliceReportCanUpload" @failed="policeReportErrorModal.openModal"
              @success="onDocumentUploaded" />
          </div>
          <div v-else class="information-container">
            <h4 class="mb-4">
              Skicka polisrapport
            </h4>
            <DropZone v-if="fraudBarrierStatus.PoliceReportCanUpload" @failed="policeReportErrorModal.openModal"
              @success="onDocumentUploaded" />
          </div>
        </template>
      </NdsCard>
    </NdsCardSpacing>
  </div>
  <NdsConfirmModal v-bind="policeReportUploadedModal.props" v-model="policeReportUploadedModal.isOpen.value"
    @onConfirm="policeReportUploadedModal.closeModal" @onCancel="policeReportUploadedModal.closeModal"
    @onDismiss="policeReportUploadedModal.dismissModal" />
  <NdsConfirmModal v-bind="policeReportErrorModal.props" v-model="policeReportErrorModal.isOpen.value"
    @onConfirm="policeReportErrorModal.closeModal" @onCancel="policeReportErrorModal.closeModal"
    @onDismiss="policeReportErrorModal.dismissModal" />
</template>
<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { NdsLink, NdsLoader, NdsCard, NdsCardSpacing, NdsConfirmModal, useConfirmModal } from '@nds/vue';
import DropZone from '../components/DropZone.vue';
import FraudBarrierToggle from '../components/FraudBarrierToggle.vue';
import { FRAUD_BARRIER_FETCH } from '../Scripts/store/modules/fraudBarrierContex';
import { useEbbot } from '../utils/ebbotUtils.js';

defineProps({
  model: Object,
  smallTitle: Boolean
});
const store = useStore();
const fraudBarrierStatus = computed(() => store.state.fraudBarrierContex.fraudBarrier);

const { showEbbot, hideEbbot } = useEbbot();

const policeReportUploadedModal = useConfirmModal({
  title: 'Polisanmälan uppladdad',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "check" },
  confirmBtnLabel: "Stäng",
  body: {
    text: "Vi kontrollerar din polisanmälan och meddelar dig via sms och e-post när den blivit godkänd eller om du behöver komplettera.",
    isRichText: true,
  },
  testId: 'police-report-uploaded-modal',
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const policeReportErrorModal = useConfirmModal({
  title: 'Polisanmälan kunde inte laddas up!',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "exclamation-triangle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: "<p>Din polisrapport kunde tyvärr inte laddas upp, vänligen försök igen.</p> <p>Kontrollera att filstorleken inte överstiger 100 mb och att filen laddas upp i formatet: pdf, tiff, png, gif, jpg eller jpeg.</p><p>Om problemet kvarstår, vänligen kontakta <a href='mailto:utredningen@uc.se' target='_blank'>utredningen@uc.se</a></p>",
    isRichText: true,
  },
  testId: 'police-report-error-modal',
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

onMounted(() => {
  if (!store.state.fraudBarrierContex.loaded) {
    store.dispatch(FRAUD_BARRIER_FETCH);
  }
});

const onDocumentUploaded = () => {
  store.dispatch(FRAUD_BARRIER_FETCH);
  policeReportUploadedModal.openModal();
}
</script>
<style lang="scss">
@import "../../../styles/abstracts/_variables.scss";

.fraudbarrier__wrapper-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: $ice-blue;
  margin: -1rem -1rem 0 -1rem;
  padding: 1rem 1rem 1rem 4rem;
}

.fraudbarrier-review-item {
  border: 1px solid $gallery;
  border-radius: 6px;

  &:before {
    font-family: "Font Awesome 5 Pro";
    content: '\f15b';
    font-size: 1.25em;
    font-weight: 400;
    position: absolute;
    top: calc(50% - 15px);
    left: 20px;
    color: $deep-ocean;
  }

  span {
    padding-left: 2rem;
  }
}

.status-label {
  color: $gray;
}

.upload-area {
  background-color: #ECF7F9;
  border: 1px dashed #333333;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  min-height: 235px;
}

.police-report-drop-zone {
  margin: 30px 0;
  padding: 15px;
  border: 2px dashed #dedede;
  color: #999;
  text-align: center;

  .file-list,
  .uploaded-file-list {
    padding: 0;
    margin: 0 0 15px 0;
    text-align: left;
    color: #333;

    li {
      list-style: none;
      overflow: hidden;
      white-space: nowrap;

      a {
        padding-right: 5px;
      }

      .fa-check {
        /*color: $green-haze;*/
        padding-right: 5px;
      }
    }

    label {
      color: #f07d00;
      cursor: pointer;
      margin: 0;
    }
  }

  label {
    cursor: pointer;
  }
}

.fraudbarrier__wrapper-header {
  position: relative;

  &:before {
    font-family: "Font Awesome 5 Pro";
    font-size: 2em;
    top: calc(50% - 24px);
    left: 15px;
    font-weight: 400;
    position: absolute;
    color: $night-rider;
  }

  &.fraudbarrier-approved:before {
    content: '\f058';
  }

  &.fraudbarrier-awaitingapproval:before {
    content: '\f06a';
  }

  &.fraudbarrier-rejected:before {
    content: '\f071';
  }
}
</style>
