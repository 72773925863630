import { formatToSek, FormatDate } from '../_helpers';

export const creditTypes = {
    creditCard: 1,
    downPayment: 4,
    unsecuredLoan: 5,
    propertyCredit: 7,
    tenantOwnership: 9,
  };

const hasNoEntries = (entries) => entries.map(({ value }) => !value).every(Boolean);
const sortSummaryByTimestamp = (summary) => (summary.sort((a, b) => a.date.timeStamp.value - b.date.timeStamp.value));
const formatChartDataReturnValue = (entries) => {
    const isEmpty = hasNoEntries(entries);
    return { entries: isEmpty ? [] : entries, isEmpty: isEmpty };
}
const emptyEntriesReturnValue = { entries: [], isEmpty: true };

export const getCreditChartData = (creditReport) => {
    if (creditReport?.ctiUnsecuredCreditSummary?.unsecureCreditSummaries) {
        return formatChartDataReturnValue(sortSummaryByTimestamp(creditReport.ctiUnsecuredCreditSummary.unsecureCreditSummaries).map((chartData) => ({
            timestamp: chartData.date.timeStamp.value,
            value: chartData.usedCreditOnAccountCredits,
            total: chartData.limitAccountCredits,
        })));
    }
    return emptyEntriesReturnValue;
}

export const getMortgagesChartData = (creditReport) => {
    if (creditReport?.ctiMortgagesSummary?.mortgagesSummaries) {
        return formatChartDataReturnValue(sortSummaryByTimestamp(creditReport.ctiMortgagesSummary.mortgagesSummaries).map((chartData) => ({
            timestamp: chartData.date.timeStamp.value,
            value: chartData.usedCreditPropertyMortgage + chartData.usedCreditTenantOwnershipMortgage,
        })));
    }
    return emptyEntriesReturnValue;
}

export const getPropertyChartData = (creditReport) => {
    if (creditReport?.ctiMortgagesSummary?.mortgagesSummaries) {
        return formatChartDataReturnValue(
            sortSummaryByTimestamp(creditReport.ctiMortgagesSummary.mortgagesSummaries).map((chartData) => ({
                timestamp: chartData.date.timeStamp.value,
                value: chartData.usedCreditPropertyMortgage,
            }))
        );
    }
    return emptyEntriesReturnValue;
};

export const getTenantOwnershipChartData = (creditReport) => {
    if (creditReport?.ctiMortgagesSummary?.mortgagesSummaries) {
        return formatChartDataReturnValue(
            sortSummaryByTimestamp(creditReport.ctiMortgagesSummary.mortgagesSummaries).map((chartData) => ({
                timestamp: chartData.date.timeStamp.value,
                value: chartData.usedCreditTenantOwnershipMortgage,
            }))
        );
    }
    return emptyEntriesReturnValue;
};

export const getHirePurchaseData = (creditReport) => {
    if (creditReport?.ctiUnsecuredCreditSummary?.unsecureCreditSummaries) {
        return formatChartDataReturnValue(sortSummaryByTimestamp(creditReport.ctiUnsecuredCreditSummary.unsecureCreditSummaries).map((chartData) => ({
            timestamp: chartData.date.timeStamp.value,
            value: chartData.usedCreditHirePurchase,
        })));
    }
    return emptyEntriesReturnValue;
}

export const getUnsecuredLoansChartData = (creditReport) => {
    if (creditReport?.ctiUnsecuredCreditSummary?.unsecureCreditSummaries) {
        return formatChartDataReturnValue(sortSummaryByTimestamp(creditReport.ctiUnsecuredCreditSummary.unsecureCreditSummaries).map((chartData) => ({
            timestamp: chartData.date.timeStamp.value,
            value: chartData.usedCreditUnsecured,
        })));
    }
    return emptyEntriesReturnValue;
}

const sortHistoricalDebtDetailsByTimestamp = (summary) => (summary.sort((a, b) => a.debtDate.timeStamp.value - b.debtDate.timeStamp.value));

export const getHistoricalDebtDetailsChartData = (creditReport) => {
    if (creditReport?.ctiConsumerPaymentInformation?.historicalDebtDetails) {
        return formatChartDataReturnValue(sortHistoricalDebtDetailsByTimestamp(creditReport?.ctiConsumerPaymentInformation?.historicalDebtDetails).map((chartData) => ({
            timestamp: chartData.debtDate.timeStamp.value,
            value: chartData.publicClaims + chartData.privateClaims,
        })));
    }
    return emptyEntriesReturnValue;
}

export const getTotalCreditDebt = (report, creditType) => {
    if (!report?.creditReport?.ctiCreditsWithInquirer?.creditInformations) return 0;
  
    return report.creditReport.ctiCreditsWithInquirer.creditInformations
      .filter((item) => report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[item.creditNumber] === creditType && !item.toDate)
      .reduce((sum, item) => sum + item.usedCredit, 0);
  }

export const formatCredits = (creditType, idPrefix, hasTerminatedFlag, report) => {
    if (!report.creditReport?.ctiCreditsWithInquirer?.creditInformations) {
      return [];
    }
  
    return report.creditReport.ctiCreditsWithInquirer.creditInformations
      .filter((creditInfo) => {
        const isMatchingType = report.creditReport.ctiCreditWithInquirerToCreditTypeMapping[creditInfo.creditNumber] === creditType;
        if (isMatchingType && creditInfo.toDate && !hasTerminatedFlag.value) {
          hasTerminatedFlag.value = true;
        }
        return isMatchingType;
      })
      .map((credit, index) => ({
        id: `${idPrefix} ${index + 1}`,
        title: credit.creditor ?? "Okänd långivare",
        subtitle: credit.usedCredit != null ? `Utnyttjad kredit: ${formatToSek(credit.usedCredit)}` : null,
        grantedCredit: credit.grantedCredit != null ? formatToSek(credit.grantedCredit) : null,
        usedCredit: credit.usedCredit != null ? formatToSek(credit.usedCredit) : null,
        fromDate: credit.fromDate?.timeStamp.value ? FormatDate(credit.fromDate.timeStamp.value) : null,
        toDate: credit.toDate?.timeStamp.value ? FormatDate(credit.toDate.timeStamp.value) : null,
        updatedAtDate: credit.updatedAtDate?.timeStamp.value ? FormatDate(credit.updatedAtDate.timeStamp.value) : null,
        creditNumber: credit.creditNumber || null
      }));
  };
  