<template>
  <div class="row" data-testid="checkout-payment">
    <div class="col-md-6 offset-md-3">
      <h2 class="text-center">
        Slutför beställning
      </h2>
      <div id="checkout-container-div" ref="netsIdContainer" class="mb-4" />
      <div v-if="model.checkoutPaymentCheckoutDescriptionBlocks"
        class="checkout-payment-wrapper rounded-border fade-in three mt-4 pt-4 px-4">
        <template v-for="(block, index) in model.checkoutPaymentCheckoutDescriptionBlocks"
          :key="`checkout_description_block_${index}`">
          <CheckoutDescriptionBlock :model="block" />
        </template>
      </div>
    </div>
  </div>
  <NdsConfirmModal v-bind="errorModal.props" v-model="errorModal.isOpen.value" @onConfirm="onCloseErrorModal"
    @onCancel="onCloseErrorModal" @onDismiss="errorModal.dismissModal" />
  <NdsConfirmModal v-bind="paymentErrorModal.props" v-model="paymentErrorModal.isOpen.value"
    @onConfirm="onConfirmPaymentError" @onCancel="paymentErrorModal.closeModal"
    @onDismiss="paymentErrorModal.dismissModal" />
</template>

<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { NdsConfirmModal, useConfirmModal } from '@nds/vue';
import { defaultGenericError } from '../modals/modalsUtils';
import { useEbbot } from '../../utils/ebbotUtils.js';
import CheckoutDescriptionBlock from '../../components/blocks/CheckoutDescriptionBlock.vue';
import { personalIdentityNumberToAge } from '../../utils/personalIdentityNumber';
import { postFreePurchase, postCreatePayment } from '../../Scripts/providers/customerApiProvider';
import { getMessagesForErrorStatus } from '../../Scripts/providers/viewApiProviders';
import { trackOnPurchase, trackOnCheckout } from '../../utils/ecommercetrackingUtils';
import {
  CHECKOUT_SELECT_STEP,
  CHECKOUT_STEP,
} from '../../Scripts/store/modules/checkoutContext';

defineProps({
  model: Object,
});

const store = useStore();

const selectedProduct = computed(() => store.state.checkoutContext.selectedProduct);
const externalCustomerId = computed(() => store.state.checkoutContext.externalCustomerId);
const customer = computed(() => store.state.checkoutContext.customer);
const isLoggedin = computed(() => store.state.appContext.isLoggedin);
const netsIdContainer = ref(null);
const redirectUrl = ref('');

const { showEbbot, hideEbbot } = useEbbot();

const errorModal = useConfirmModal({
  ...defaultGenericError,
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const paymentErrorModal = useConfirmModal({
  title: defaultGenericError.title,
  variant: 'primary',
  prefix: { prefix: "far", iconName: "globe" },
  confirmBtnLabel: "Stäng",
  body: {
    text: defaultGenericError.body.text,
    isRichText: true,
  },
  testId: 'payment-error-modal',
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const onCloseErrorModal = () => {
  store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.ENTER_INFORMATION });
  return errorModal.closeModal();
}

const onConfirmPaymentError = () => {
  if (redirectUrl.value) {
    return window.location.href = redirectUrl.value;
  }
  return paymentErrorModal.closeModal();
}

const handlePaymentError = ({ statusCode }) => {
  errorModal.closeModal();
  paymentErrorModal.closeModal();
  getMessagesForErrorStatus({ statusCode }).then((response) => {
    const {
      errorTitle,
      errorText,
      errorTextLoggedIn,
      errorAlreadyBoughtCtaText,
      errorAlreadyBoughtCtaUrl,
    } = response.data;
    paymentErrorModal.setProps({
      title: errorTitle,
      body: {
        text: isLoggedin.value ? errorTextLoggedIn || errorText : errorText,
        isRichText: true,
      },
      variant: 'primary',
      prefix: { prefix: "far", iconName: "globe" },
      confirmBtnLabel: errorAlreadyBoughtCtaText || 'Stäng',
      testId: 'payment-error-modal',
      onOpenCallback: hideEbbot,
      onDismissCallback: showEbbot,
      onCloseCallback: showEbbot,
    });
    redirectUrl.value = errorAlreadyBoughtCtaUrl;
    paymentErrorModal.openModal();
    return;
  }).catch(() => {
    errorModal.openModal();
    return;
  });
}

const createPayment = () => {
  // TODO: Return state to complete to test localy
  // store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.COMPLETE, isLoggedin: isLoggedin.value });

  const freeUnder21 = selectedProduct.value.freeForUnder21;
  const age = personalIdentityNumberToAge(customer.value.ssn);
  if (freeUnder21 && age < 21) {
    postFreePurchase({
      customerId: externalCustomerId.value,
      productPackageIds: selectedProduct.value.productPackage.id
    }).then(() => {
      store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.ENTER_INFORMATION_COMPLETE });
    }).catch((error) => {
      handlePaymentError({ statusCode: error.status });
      return;
    });
  } else {
    postCreatePayment({
      externalCustomerId: externalCustomerId.value,
      productPackageIds: selectedProduct.value.productPackage.id,
    }).then((response) => {
      if (netsIdContainer.value && netsIdContainer.value.innerHTML !== '') {
        netsIdContainer.value.innerHTML = '';
      }
      const paymentId = response.data?.paymentId;
      const checkoutKey = document.getElementById('netscheckoutkey')?.value;
      if (!checkoutKey || checkoutKey === '' || !paymentId || paymentId === '') {
        // TODO handle error better when netsid is down
        handlePaymentError({ statusCode: 400 });
        return;
      }
      const checkoutOptions = {
        checkoutKey,
        paymentId,
        containerId: 'checkout-container-div',
        language: 'sv-SE'
      };
      trackOnCheckout({ product: selectedProduct.value });
      const checkout = new window.Dibs.Checkout(checkoutOptions);
      checkout.on('payment-completed', () => {
        trackOnPurchase({ paymentId, product: selectedProduct.value });
        store.dispatch(CHECKOUT_SELECT_STEP, { step: CHECKOUT_STEP.COMPLETE, isLoggedin: isLoggedin.value });
      });
      return;
    }).catch((error) => {
      handlePaymentError({ statusCode: error.status || 400 });
      return
    });
  }
}

onMounted(() => {
  if (externalCustomerId.value) {
    createPayment();
  } else {
    handlePaymentError({ statusCode: 400 });
  }
});

</script>
<style lang="scss" scoped>
@import "../../../../styles/abstracts/_variables.scss";

.checkout-payment-wrapper {
  background: $beach-secondary;
}
</style>