<template>
  <div class="row" data-testid="digital-mail-box-messages">
    <div v-if="messages?.length > 0" class="col-12">
      <NdsTable :headers="[
        { title: 'Datum', value: 'dateValue' },
        { title: 'Företag', value: 'title' },
        { title: '', value: 'statusIcon', align: 'end' },
      ]" :items="paginatedItems" alternateRowColors compact>
        <template v-slot:item="{ item }">
          <tr v-if="isMobile">
            <td class="pb-1">
              <div class="d-flex justify-content-between flex-wrap">
                <NdsText tight text="Datum:" />
                <NdsButton v-if="item.inactiveText" disabled size="small" theme="enento" variant="text"
                  :prefix="{ prefix: 'far', iconName: item.statusIcon }" width="fixed" :testId="item.statusIcon">
                  {{ item.inactiveText }} {{ item.dateValue }}
                </NdsButton>
                <NdsButton v-else disabled size="small" theme="enento" variant="text"
                  :prefix="{ prefix: 'far', iconName: item.statusIcon }" width="fixed" :testId="item.statusIcon">
                  {{ item.dateValue }}
                </NdsButton>
              </div>
              <div class="d-flex justify-content-between flex-wrap">
                <NdsText tight text="Företag:" />
                <NdsLink v-if="item.href" size="small" :href="item.href" newTab
                  @click="onHandleClickOpenMail(item.deliveryId)">
                  {{ item.title }}
                </NdsLink>
                <NdsText v-else tight :text="item.title" />
              </div>
            </td>
          </tr>
          <tr v-else>
            <td class="text-no-wrap">{{ item.dateValue }}</td>
            <td>
              <NdsLink v-if="item.href" :href="item.href" newTab @click="onHandleClickOpenMail(item.deliveryId)">
                {{ item.title }}
              </NdsLink>
              <NdsText v-else tight :text="item.title" />
            </td>
            <td class="icon_cell_class">
              <div class="text-nowrap">
                <NdsIconButton disabled size="small" theme="enento" variant="text" :iconName="item.statusIcon"
                  :testId="item.statusIcon" />
                <NdsText v-if="item.inactiveText" tight size="xs" :text="item.inactiveText" />
              </div>
            </td>
          </tr>
        </template>
      </NdsTable>
      <div v-if="totalPages > 1" class="mt-4">
        <NdsPagination :length="totalPages" v-model="currentPage" class="ul-margin-resetter" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { NdsPagination, NdsLink, usePagination, NdsTable, NdsButton, NdsIconButton, NdsText } from "@nds/vue";
import { FormatDate } from '../_helpers.js';
import { useSiteBreakpoints } from '../utils/breakpointsUtils';
import { CUSTOMER_INFO_FETCH_NUMBER_OF_NEW_MAIL_NOTIFICATIONS } from '../Scripts/store/modules/customerInfoContex.js';

const props = defineProps({
  messages: Array,
});

const store = useStore();
const { isMobile } = useSiteBreakpoints();
const onHandleClickOpenMail = (deliveryId) => {
  // we need to delay the loockup since the call to fetch the mail and open it in a new tab takes some time and it updates the read count
  setTimeout(() => store.dispatch(CUSTOMER_INFO_FETCH_NUMBER_OF_NEW_MAIL_NOTIFICATIONS, { deliveryId }), 1000);
};

const getStatusIcon = (message) => {
  if (message.href && message.unread) {
    return 'envelope';
  }
  if (message.href) {
    return 'envelope-open';
  }
  return 'file-times';
}

const formatedMailsTableItems = computed(() => (props.messages.map(message => ({
  dateValue: FormatDate(Number(message.eventDate)),
  statusIcon: getStatusIcon(message),
  inactiveText: message.href ? '' : 'Ej Aktuell',
  title: message.inquirers,
  deliveryId: message.deliveryId,
  href: message.href,
}))));

const {
  currentPage,
  totalPages,
  paginatedItems,
} = usePagination(formatedMailsTableItems, 10);

</script>
<style lang="scss" scoped>
@import "../../../styles/abstracts/_variables.scss";

.icon_cell_class {
  justify-items: end;
  div {
    display: flex;
    align-self: center;
  }
}
</style>