<template>
    <NdsConfirmModal v-if="enabled" v-bind="accountNotVerifedModal.props" v-model="accountNotVerifedModal.isOpen.value"
        @onConfirm="accountNotVerifedModal.props.state.status === 'error' ? onRetry() : submitCustomerSettings()"
        @onCancel="onLogoutClicked"
        :confirmBtnLabel="accountNotVerifedModal.props.state.status === 'error' ? 'Försök igen' : 'Spara'"
        :testId="testId">
        <template #body>
            <NdsAccordion :accordions="[{ id: moreInformationId, title: contactInformation.moreInformationTitle }]">
                <template #[moreInformationId]>
                    <NdsText :text="contactInformation.moreInformationText" isRichText />
                </template>
            </NdsAccordion>
            <form class="row">
                <div class="col-12 pt-3">
                    <div :class="!isMobile && 'form-item-container'">
                        <label for="email">{{ helperTexts.emailLabel }}</label>
                        <input id="email" v-model="form.customerEmail" type="text" class="form-control"
                            :class="v$.customerEmail.$error ? 'is-invalid' : ''" :placeholder="helperTexts.placeholderEmail"
                            @keyup="onDataChange">
                        <small v-if="v$.customerEmail.email.$invalid" class="is-invalid">
                            {{ v$.customerEmail.email.$message }}
                        </small>
                        <small v-else-if="v$.customerEmail.required.$invalid" class="is-invalid">
                            {{ v$.customerEmail.required.$message }}
                        </small>
                    </div>
                    <div :class="!isMobile && 'form-item-container'" class="pt-3">
                        <label for="phone">{{ helperTexts.phoneLabel }}</label>
                        <input id="phone" v-model="form.customerPhone" type="text" class="form-control"
                            :placeholder="helperTexts.placeholderPhone" :class="v$.customerPhone.$error ? 'is-invalid' : ''"
                            @keyup="onDataChange">
                        <small v-if="v$.customerPhone.valid_cellphone.$invalid" class="is-invalid">
                            {{ v$.customerPhone.valid_cellphone.$message }}
                        </small>
                        <small v-else-if="v$.customerPhone.required.$invalid" class="is-invalid">
                            {{ v$.customerPhone.required.$message }}
                        </small>
                    </div>
                </div>
            </form>
        </template>
    </NdsConfirmModal>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, reactive, watch, ref } from 'vue';
import { NdsConfirmModal, useConfirmModal, NdsAccordion, NdsText } from '@nds/vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import { isValidSwedishCellPhone } from '../../utils/validators.js';
import { getRequestVerificationToken } from '../../utils/authUtils';
import { clearPersistedStorage } from '../../Scripts/store/storeUtils';
import {
    CUSTOMER_INFO_POST_SETTINGS,
} from '../../Scripts/store/modules/customerInfoContex';
import { useSiteBreakpoints } from '../../utils/breakpointsUtils';
import helperTexts from '../../../../json/helperTexts.json';
import { useEbbot } from '../../utils/ebbotUtils.js';

const store = useStore();
const { isMobile } = useSiteBreakpoints();

const enabled = computed(() => store.state.appContext.menu.verificationModal.enabled);
const isContactInformationVerified = computed(() => store.state.customerInfoContex.customerEssentials.contactInformationVerified);
const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const customerEssentialsLoaded = computed(() => store.state.customerInfoContex.loaded);
const customerEssentialsPending = computed(() => store.state.customerInfoContex.pending);
const customerEssentialsFailed = computed(() => store.state.customerInfoContex.failed);
const confirmContactInformation = computed(() => store.state.appContext.menu.verificationModal.confirmContactInformation);
const provideContactInformation = computed(() => store.state.appContext.menu.verificationModal.provideContactInformation);
const hasEmailAndPhonenumber = computed(() => customerEssentials.value.customerEmail && customerEssentials.value.customerPhone);
const testId = computed(() => hasEmailAndPhonenumber.value ? 'confirm-contact-information-modal' : 'provide-contact-information-modal');
const contactInformation = computed(() => hasEmailAndPhonenumber.value ? confirmContactInformation.value : provideContactInformation.value);

const dataChanged = ref(false);
const moreInformationId = ref('moreInformationId');

const STATUS = Object.freeze({
    PRESTINE: 'PRESTINE',
    INITIATED: 'INITIATED',
    PENDING: 'PENDING',
    ERROR: 'ERROR',
});
const modalStatus = ref(STATUS.PRESTINE);

const form = reactive({
    customerEmail: customerEssentials.value.customerEmail,
    customerPhone: customerEssentials.value.customerPhone,
    notificationBySms: customerEssentials.value.notificationBySms,
    notificationByMail: customerEssentials.value.notificationByMail,
    newsLetterSubscription: customerEssentials.value.newsLetterSubscription,
});

const rules = computed(() => {
    return {
        customerEmail: {
            required: helpers.withMessage('Vänligen fyll i detta fält', required),
            email: helpers.withMessage('Vänligen fyll i en giltig e-postadress', email)
        },
        customerPhone: {
            valid_cellphone: {
                $validator: isValidSwedishCellPhone,
                $message: "Vänligen fyll i ett giltigt mobilnummer"
            },
            required: helpers.withMessage('Vänligen fyll i detta fält', required)
        },
    }
});
const v$ = useVuelidate(rules, form);

const onDataChange = () => {
    v$.value.$validate().then(() => {
        if (!v$.value.$error) {
            dataChanged.value = true;
        } else {
            dataChanged.value = false;
        }
    });
};

const submitCustomerSettings = () => {
    v$.value.$validate().then(() => {
        if (!v$.value.$error) {
            const csrfToken = getRequestVerificationToken();
            store.dispatch(CUSTOMER_INFO_POST_SETTINGS, {
                csrfToken,
                email: form.customerEmail,
                phoneNumber: form.customerPhone,
                smsNotification: form.notificationBySms,
                newsLetterSubscription: form.newsLetterSubscription,
                mailNotification: form.notificationByMail,
            });
        }
    });
};

const { showEbbot, hideEbbot } = useEbbot();

const accountNotVerifedModal = useConfirmModal({
    title: contactInformation.value.title,
    confirmBtnLabel: contactInformation.value.submitLabel,
    cancelBtnLabel: contactInformation.value.cancelLabel,
    persistent: true,
    fullscreen: false,
    onOpenCallback: hideEbbot,
    onCloseCallback: showEbbot,
});

onMounted(() => {
    if (store.state.appContext.isLoggedin && store.state.customerInfoContex.loaded && !store.state.customerInfoContex.customerEssentials.contactInformationVerified && !isContactInformationVerified.value) {
        modalStatus.value = STATUS.INITIATED;
        accountNotVerifedModal.openModal();
    }
});

watch(isContactInformationVerified, (value) => {
    if (value) {
        accountNotVerifedModal.closeModal();
    }
});

watch(customerEssentialsPending, (value) => {
    if (value && modalStatus.value === STATUS.ERROR) {
        modalStatus.value = STATUS.PENDING;
        accountNotVerifedModal.setState({ status: "pending", message: "...sparar dina uppgifter" });
    }
});

watch(customerEssentialsFailed, (value) => {
    if (value && modalStatus.value === STATUS.INITIATED) {
        modalStatus.value = STATUS.ERROR;
        accountNotVerifedModal.setState({ status: "error", message: "Något gick fel, försök igen" });
    }
});

watch(customerEssentialsLoaded, (value) => {
    if (value && !isContactInformationVerified.value && (modalStatus.value === STATUS.PRESTINE || modalStatus.value === STATUS.PENDING)) {
        form.customerEmail = customerEssentials.value.customerEmail;
        form.customerPhone = customerEssentials.value.customerPhone;
        form.notificationBySms = customerEssentials.value.notificationBySms;
        form.notificationByMail = customerEssentials.value.notificationByMail;
        form.newsLetterSubscription = customerEssentials.value.newsLetterSubscription;
        modalStatus.value = STATUS.INITIATED;
        accountNotVerifedModal.openModal();
    }
});


const onRetry = () => {
    accountNotVerifedModal.setState({ status: "default" });
};

const onLogoutClicked = () => {
    clearPersistedStorage();
    window.location.href = '/Logout';
}
</script>
<style lang="scss">
@import "../../../../styles/abstracts/_variables.scss";
@import "../../../../styles/abstracts/_breakpoints.scss";

.form-item-container label {
    color: #333333;
    font-size: 14px;
    font-weight: 700;
}

.alert-dismissible {
    padding-left: 65px;
}

.hide {
    display: none;
}

input {
    border-color: $silver;
}

/* .form-item-container {
    max-width: 380px;
} */

.check-marks {
    list-style: none;
    overflow: auto;
    margin-left: 0;
    padding-left: 1.75rem;
}

.check-marks li {
    margin: 0.75rem 0;
    position: relative;
}

.check-marks li::before {
    font-family: "Font Awesome 5 Pro";
    display: inline-block;
    content: "\2713";
    color: #008EAA;
    position: absolute;
    font-size: .875em;
    left: -1.5rem;
    top: 4px;
}
</style>