<template>
  <NdsCard :heading="{ title: 'Anmärkningar', type: 'h3', prefix: { prefix: 'far', iconName: 'comment-exclamation' } }"
    testId="minupplysning-mobile-remarks-tab">
    <template #controls>
      <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle" @click="infoModal.openModal"
        aria-label="Anmärkningar förklaring" />
    </template>
    <template #default>
      <p
        v-if="report.creditReport.ctiConsumerPaymentInformation && report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified && report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified.length > 0">
        Det finns <span class="font-weight-medium">{{
          report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified.length
        }} st</span>
        anmärkningar på totalt <span class="font-weight-medium">
          {{ complaintAmount }}</span>
        registrerade.
      </p>
      <div v-if="report.creditReport.ctiConsumerPaymentInformation" class="mb-3">
        <Remarks :show-end-date="false"
          :complaints="report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified" />
      </div>
      <RemarksKfm v-if="report.creditReport.ctiConsumerPaymentInformation"
        :complaints="report.creditReport.ctiConsumerPaymentInformation" :model="model" />
      <ZeroState v-else icon="far fa-comment-exclamation" text="Ingen anmärkning registrerad" />
      <NdsFooter class="pt-3" :prefix="{ prefix: 'far', iconName: 'book' }"
        text="Källa: Kronofogden, tingsrätt och kreditgivare" tight />
    </template>
  </NdsCard>
  <NdsConfirmModal v-bind="infoModal.props" v-model="infoModal.isOpen.value" @onConfirm="infoModal.closeModal"
    @onCancel="infoModal.closeModal" @onDismiss="infoModal.dismissModal" />
</template>
<script setup>
import { computed } from 'vue';
import { NdsIconButton, NdsConfirmModal, NdsCard, NdsFooter, useConfirmModal } from '@nds/vue';
import Remarks from '../Remarks.vue';
import ZeroState from '../../components/myinformation/ZeroState.vue';
import RemarksKfm from "../RemarksKfm.vue";
import { formatToSek } from '../../_helpers.js';
import { useEbbot } from '../../utils/ebbotUtils.js';

const props = defineProps({
  report: Object,
  introText: String,
  model: Object
});

const complaintAmount = computed(() => {
  if (props.report.creditReport?.ctiConsumerPaymentInformation?.historicalComplaintsSpecified) {
    return formatToSek(props.report.creditReport.ctiConsumerPaymentInformation.historicalComplaintsSpecified.map(({ complaintAmount }) => complaintAmount).reduce((a, b) => a + b, 0))
  }
  return formatToSek(0);
});

const { showEbbot, hideEbbot } = useEbbot();

const infoModal = useConfirmModal({
  title: 'Anmärkningar',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: props.introText,
    isRichText: true,
  },
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

</script>