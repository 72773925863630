<template>
  <div id="digitalmailboxpage" class="dashboard" data-testid="dashboard-digital-mailbox-page">
    <div class="row">
      <div class="col-12 mb-2 d-md-none mobile-return-overview-btn">
        <RouterLink to="/mina-tjanster" v-slot="{ href, navigate, isActive }" custom>
          <NdsLink :href="href" :active="isActive" @click="navigate" :prefix="{ prefix: 'far', iconName: 'home-alt' }">
            Översikt
          </NdsLink>
        </RouterLink>
      </div>
      <div class="col-12">
        <NdsCard :heading="{ title: model.name, type: 'h3', prefix: { prefix: 'far', iconName: 'envelope' } }">
          <template #default>
            <Reloader v-if="failed" @reload="fetchNewDigitalMailBoxMessages" />
            <template v-else>
              <div class="d-flex align-items-center mb-4">
                <span class="font-weight-medium">Status</span>
                <input id="switch" v-model="isActiveRef" type="checkbox" class="enento__toggle-input"
                  :disabled="pending" @change="toggleDigitalMailbox">
                <label class="enento__toggle mb-0 ml-auto ml-md-0" :class="pending && 'enento__toggle-disabled'"
                  for="switch" data-testid="digital-brevlada-page-switch-btn">
                  <i class="far fa-check fade-in-half" />
                  <span class="enento__toggle-on">På</span>
                  <span class="enento__toggle-off">Av</span>
                </label>
              </div>
              <NdsAccordion :accordions="[{ id: moreInformationId, title: model.whatIsDigitalMailbox?.value || 'Vad är Digital Bevlåda?' }]"
                testId="digital-brevlada-page-about-digital-brevlada-container">
                <template #[moreInformationId]>
                  <NdsCard>
                    <NdsText tight :text="model.aboutText.value" isRichText />
                    <NdsText v-if="model.readMoreLinkTitle?.value" tight :text="model.readMoreLinkTitle.value" />
                    <NdsLink v-if="model.readMoreLink?.value" :href="model.readMoreLink.value" variant="inline"
                      :prefix="{ prefix: 'far', iconName: 'external-link' }" newTab>
                      {{ model.readMoreLinkLabel?.value || "Läs mer!" }}
                    </NdsLink>
                  </NdsCard>
                </template>
              </NdsAccordion>
              <div v-if="isActive && hasMails" class="mb-2 mt-2">
                <NdsText tight :text="numberOfMailsText" isRichText />
              </div>
              <div v-if="pending" class="align-items-center justify-content-center d-flex">
                <NdsLoader />
              </div>
              <template v-else-if="isActive">
                <div class="mb-4 mt-2">
                  <NdsText tight :text="model.descriptionWhenActivated.value" isRichText />
                </div>
                <DigitalMailBoxMessages v-if="hasMails" :messages="customerMails" />
                <ZeroState v-else icon="far fa-envelope" :text="model.noMessagesPreamble.value" />
              </template>
              <div v-else class="mb-4 mt-2">
                <NdsText tight :text="model.descriptionWhenDisabled.value" isRichText />
              </div>
            </template>
          </template>
        </NdsCard>
      </div>
    </div>
  </div>
  <NdsConfirmModal v-bind="errorModal.props" v-model="errorModal.isOpen.value" @onConfirm="errorModal.closeModal"
    @onCancel="errorModal.closeModal" @onDismiss="errorModal.dismissModal" />
</template>
<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { NdsLoader, NdsLink, NdsCard, NdsConfirmModal, useConfirmModal, NdsText, NdsAccordion } from '@nds/vue';
import DigitalMailBoxMessages from '../components/DigitalMailBoxMessages.vue';
import Reloader from '../components/Reloader.vue';
import { defaultGenericError } from '../components/modals/modalsUtils';
import { useEbbot } from '../utils/ebbotUtils.js';
import { postSetDigitalMailBoxActive } from '../Scripts/providers/frontendApiControllerProviders';
import {
  CUSTOMER_INFO_FETCH_MAILS,
} from '../Scripts/store/modules/customerInfoContex';
import ZeroState from '../components/myinformation/ZeroState.vue';
import { formatDataInTemplate } from '../_helpers';

const props = defineProps({
  model: Object,
});

const store = useStore();
const customerMails = computed(() => store.state.customerInfoContex.customerMails);
const hasMails = computed(() => store.state.customerInfoContex.customerMails.length > 0);
const isActive = computed(() => store.state.customerInfoContex.mailsIsActivated);
const isActiveRef = ref(store.state.customerInfoContex.mailsIsActivated);
const failed = computed(() => store.state.customerInfoContex.mailsFailed);
const pending = computed(() => store.state.customerInfoContex.mailsPending);
const numberOfMailsText = computed(() => (props.model.amountOfNewMailsText?.value ? formatDataInTemplate({
    template: props.model.amountOfNewMailsText.value,
    count: store.state.customerInfoContex.customerUnreadMails,
    options: { suffix: "st" }
  }) : '')
);
const moreInformationId = ref('moreInformationId');

const fetchNewDigitalMailBoxMessages = () => {
  store.dispatch(CUSTOMER_INFO_FETCH_MAILS);
}

watch(isActive, (value) => {
  isActiveRef.value = value;
})

const { showEbbot, hideEbbot } = useEbbot();

const errorModal = useConfirmModal({
  ...defaultGenericError,
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});


const toggleDigitalMailbox = () => {
  postSetDigitalMailBoxActive({ setActive: isActive.value ? "OFF" : "ON" }).then(() => {
    fetchNewDigitalMailBoxMessages();
  }).catch(() => {
    errorModal.openModal();
  })
};

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  fetchNewDigitalMailBoxMessages();
});
</script>
