<template>
  <Reloader v-if="umbrellaMembersFailed" @reload="reFetchUmbrellaMembers" />
  <div v-if="umbrellaMembersPending" class="align-items-center justify-content-center d-flex">
    <NdsLoader />
  </div>
  <div v-if="umbrellaMembersLoaded" class="group-invite-container d-flex flex-column">
    <NdsHeading title="Medlemmar" type="h4" tight />
    <NdsText isRichText tight :text="`<p>
      Här kan du se vilka som är medlemmar i ditt ID-Skydd, bjuda in nya och säga upp befintliga medlemmar. Du kan
      bjuda in <strong>${numberOfInvitationsLeft} personer</strong> till.
    </p>`" />

    <template v-if="hasSentInvetationsOrHasMembers">
      <NdsHeading :title="`Inbjudna medlemmar (${numberOfMembersAndInvites} st)`" type="h4" tight />
      <table class="table d-none d-lg-table">
        <thead>
          <tr>
            <td style="width: 25%">Namn</td>
            <td style="width: 35%">Mail</td>
            <td>Status</td>
            <td style="width: 25%" />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(invite, index) in invitations" :key="`group_invite_invetation_${index}`"
            :class="oddOrEven(index + 1)">
            <td>{{ invite.name }}</td>
            <td>{{ invite.email }}</td>
            <td>
              <NdsStatusBadge variant="not_good">
                {{ getStatusTextWithDate({ status: STATUS.INVITED, date: invite.inviteTime }) }}
              </NdsStatusBadge>
            </td>
            <td class="float-right">
              <NdsIconButton theme="enento" variant="text" prefix="far" iconName="trash-alt" aria-label="delete member"
                @click="deleteGroupInvite({ name: invite.name })">
              </NdsIconButton>
            </td>
          </tr>
          <tr v-for="(member, index) in members" :key="`group_invite_memeber_${index}`"
            :class="oddOrEven(index + invitations.length + 1)">
            <td>{{ member.name }}</td>
            <td>{{ member.email }}</td>
            <td v-if="!member.zombie">
              <NdsStatusBadge variant="excellent">
                {{ getStatusTextWithDate({ status: STATUS.ACCEPTED, date: member.acceptTime }) }}
              </NdsStatusBadge>
            </td>
            <td v-else>
              <NdsStatusBadge variant="warning">
                {{ getStatusTextWithDate({ status: STATUS.DELETED, date: member.endDate }) }}
              </NdsStatusBadge>
            </td>
            <td class="float-right">
              <NdsIconButton :disabled="member.zombie" theme="enento" variant="text" prefix="far" iconName="trash-alt"
                aria-label="delete member"
                @click="deleteGroupMembership({ memberId: member.purchasedProductPackageId, ownerId: umbrella.purchasedProductPackageId, name: member.name })">
              </NdsIconButton>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <div v-if="hasSentInvetationsOrHasMembers" class="mobile__group__invite-wrapper mt-2 d-lg-none">
      <div v-for="(invite, index) in invitations" :key="`group_invite_list_${index}`"
        class="mobile__group__invite__member mb-4 bg-lightsmoke" :class="oddOrEven(index + 1)">
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">Namn</span>
          <span class="color-gray">{{ invite.name }}</span>
        </div>
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">E-post</span>
          <span class="color-gray">{{ invite.email }}</span>
        </div>
        <div class="position-relative py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">Status</span>
          <div class="d-flex align-items-center justify-content-between">
            <div>
              <NdsStatusBadge variant="not_good">
                {{ getStatusTextWithDate({ status: STATUS.INVITED, date: invite.inviteTime }) }}
              </NdsStatusBadge>
            </div>
            <NdsIconButton theme="enento" variant="text" prefix="far" iconName="trash-alt"
              @click="deleteGroupInvite({ name: invite.name })">
            </NdsIconButton>
          </div>
        </div>
      </div>
      <div v-for="(member, index) in members" :key="`group_invite_memeber_info_${index}`"
        class="mobile__group__invite__member mb-4 bg-lightsmoke" :class="oddOrEven(index + invitations.length + 1)">
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">Namn</span>
          <span class="color-gray">{{ member.name }}</span>
        </div>
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">E-post</span>
          <span class="color-gray">{{ member.email }}</span>
        </div>
        <div class="py-2 px-3 border-bottom">
          <span class="d-block font-weight-medium mb-1">Status</span>
          <div class="d-flex align-items-center justify-content-between">
            <div v-if="!member.zombie">
              <NdsStatusBadge variant="excellent">
                {{ getStatusTextWithDate({ status: STATUS.ACCEPTED, date: member.acceptTime }) }}
              </NdsStatusBadge>
            </div>
            <div v-else>
              <NdsStatusBadge variant="warning">
                {{ getStatusTextWithDate({ status: STATUS.DELETED, date: member.endDate }) }}
              </NdsStatusBadge>
            </div>
            <NdsIconButton theme="enento" variant="text" prefix="far" iconName="trash-alt" :disabled="member.zombie"
              @click="deleteGroupMembership({ memberId: member.purchasedProductPackageId, ownerId: umbrella.purchasedProductPackageId, name: member.name })">
            </NdsIconButton>
          </div>
        </div>
      </div>
    </div>
    <div v-if="canInviteMoreMembers">
      <hr v-if="hasSentInvetationsOrHasMembers" class="pb-3">
      <InviteMember :groupId="groupId" :invitations="invitations" />
    </div>
  </div>
  <NdsConfirmModal v-bind="deleteGroupMembershipModal.props" v-model="deleteGroupMembershipModal.isOpen.value"
    @onConfirm="onDeletedGroupMembership" @onCancel="deleteGroupMembershipModal.closeModal"
    @onDismiss="deleteGroupMembershipModal.dismissModal">
    <template #body>
      <p>Vill du säga upp medlemenskap för <strong>{{ selectedMember.name }}</strong>?</p><br />
      <p>En medlem sägs upp med en månads uppsägningstid och därefter kan du bjuda in någon ny till din grupp.</p>
    </template>
  </NdsConfirmModal>
  <NdsConfirmModal v-bind="deleteInviteModal.props" v-model="deleteInviteModal.isOpen.value" @onConfirm="onDeleteInvite"
    @onCancel="deleteInviteModal.closeModal" @onDismiss="deleteInviteModal.dismissModal">
    <template #body>
      <p>Vill du ta bort inbjudan för <strong> {{ selectedInvite.name }}</strong>.<br /><br /> En Inbjudan kan tas bort
        direkt och utan bindningstid.</p>
    </template>
  </NdsConfirmModal>
</template>
<script setup>
import { computed, ref } from 'vue';
import { NdsIconButton, NdsConfirmModal, NdsStatusBadge, NdsHeading, NdsText, NdsLoader, useConfirmModal } from '@nds/vue';
import { useStore } from 'vuex';
import InviteMember from './InviteMember.vue';
import Reloader from '../components/Reloader.vue';
import { defaultGenericError } from '../components/modals/modalsUtils';
import { useEbbot } from '../utils/ebbotUtils.js';
import { UMBRELLA_MEMBERS_FETCH } from '../Scripts/store/modules/umbrellaMembersContext';
import { getRequestVerificationToken } from '../utils/authUtils';
import { postDeleteGroupMembership, postDeleteGroupInvite } from '../Scripts/providers/customerApiProvider';

const props = defineProps({
  groupId: Number,
});

const store = useStore();

const invitations = computed(() => store.state.umbrellaMembersContext.umbrellaMembers?.invitations);
const members = computed(() => store.state.umbrellaMembersContext.umbrellaMembers.members);
const umbrella = computed(() => store.state.umbrellaMembersContext.umbrellaMembers.umbrella);
const umbrellaMembersLoaded = computed(() => store.state.umbrellaMembersContext.loaded);
const umbrellaMembersFailed = computed(() => store.state.umbrellaMembersContext.failed);
const umbrellaMembersPending = computed(() => store.state.umbrellaMembersContext.pending);

const STATUS = {
  INVITED: 'INVITED',
  ACCEPTED: 'ACCEPTED',
  DELETED: 'DELETED',
};

const selectedInvite = ref({
  name: '',
  groupId: props.groupId,
});
const selectedMember = ref({
  memberId: 0,
  ownerId: 0,
  name: ''
});
const numberOfInvitationsLeft = computed(() => (umbrella.value.size - (members.value.length + invitations.value.length)));
const numberOfMembersAndInvites = computed(() => (members.value.length + invitations.value.length));
const canInviteMoreMembers = computed(() => umbrella.value.size != null && (members.value.length + invitations.value.length) < umbrella.value.size);
const hasSentInvetationsOrHasMembers = computed(() => (members.value.length + invitations.value.length) !== 0);

const reFetchUmbrellaMembers = () => {
  store.dispatch(UMBRELLA_MEMBERS_FETCH, { umbrellaProductId: store.state.customerInfoContex.customerEssentials.customerProducts.umbrellaProductId });
}

const oddOrEven = (index) => (index % 2) > 0 ? "odd" : "even";

const { showEbbot, hideEbbot } = useEbbot();

const deleteGroupMembershipModal = useConfirmModal({
  title: 'Säg upp medlem',
  variant: 'warning',
  prefix: { prefix: "far", iconName: "user-times" },
  cancelBtnLabel: "Stäng",
  confirmBtnLabel: "Säg upp medlem",
  testId: 'delete-member-modal',
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const onDeletedGroupMembership = () => {
  const csrfToken = getRequestVerificationToken();
  postDeleteGroupMembership({ memberId: selectedMember.value.memberId, ownerId: selectedMember.value.ownerId, csrfToken }).then(({ data }) => {
    if (data?.success) {
      store.dispatch(UMBRELLA_MEMBERS_FETCH, { umbrellaProductId: store.state.customerInfoContex.customerEssentials.customerProducts.umbrellaProductId });
      return deleteGroupMembershipModal.setState({
        status: "complete",
        message: 'Medlemen är nu uppsagd och kommer få ett mejl att tjänsten avslutas.',
      });
    }
    return deleteGroupMembershipModal.setState({
        status: "error",
        message: defaultGenericError.body.text,
      });
  }).catch(() => {
    return deleteGroupMembershipModal.setState({
        status: "error",
        message: defaultGenericError.body.text,
      });
  });
};

const deleteInviteModal = useConfirmModal({
  title: 'Ta bort inbjudan',
  variant: 'warning',
  prefix: { prefix: "far", iconName: "user-times" },
  cancelBtnLabel: "Stäng",
  confirmBtnLabel: "Ta bort inbjudan",
  testId: 'delete-invite-modal',
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const onDeleteInvite = () => {
  const csrfToken = getRequestVerificationToken();
  postDeleteGroupInvite({ groupId: selectedInvite.value.groupId, name: selectedInvite.value.name, csrfToken }).then(({ data }) => {
    if (data?.success) {
      store.dispatch(UMBRELLA_MEMBERS_FETCH, { umbrellaProductId: store.state.customerInfoContex.customerEssentials.customerProducts.umbrellaProductId });
      return deleteInviteModal.setState({
        status: "complete",
        message: 'Medlemenens inbjudan är nu bortagen',
      });
    }
    return deleteInviteModal.setState({
      status: "error",
      message: defaultGenericError.body.text,
    });
  }).catch(() => {
    return deleteInviteModal.setState({
      status: "error",
      message: defaultGenericError.body.text,
    });
  });
};

const deleteGroupInvite = ({ name }) => {
  selectedInvite.value.name = name;
  deleteInviteModal.setState({ status: 'default' });
  deleteInviteModal.openModal();
};

const deleteGroupMembership = ({ memberId, ownerId, name }) => {
  selectedMember.value = { memberId, ownerId, name };
  deleteGroupMembershipModal.setState({ status: 'default' });
  deleteGroupMembershipModal.openModal();
};

const getStatusTextWithDate = ({ status, date }) => {
  const dateFormatter = Intl.DateTimeFormat('sv-SE');
  const formatedDate = date ? dateFormatter.format(new Date(Number(date))) : '';
  switch (status) {
    case STATUS.ACCEPTED:
      return `Accepterad ${formatedDate}`
    case STATUS.INVITED:
      return `Inbjuden ${formatedDate}`
    case STATUS.DELETED:
    default:
      return `Avslutas ${formatedDate}`
  }
}

</script>
<style lang="scss" scoped>
@import "../../../styles/abstracts/_variables.scss";
@import "../../../styles/abstracts/_breakpoints.scss";

.group-invite-container {
  .mobile__group__invite__member {
    a {
      position: absolute;
      right: 16px;
      top: calc(50% - 15px);
    }
  }

  .status-label {
    background-color: $light-smoke;
    border-radius: 6px;
    padding: 2px 4px;
    text-align: center;

    &.invited {
      color: $orangegold;
      background-color: rgba(242, 201, 76, 0.1);
    }

    &.active {
      color: $green-haze;
      background-color: rgba(38, 153, 82, 0.1);
    }

    &.cancelled {
      color: $monza;
      background-color: $feedback-error-300;
    }
  }

  table {
    .even {
      background-color: $white;
    }

    tr:hover td {
      background: inherit;
      color: inherit;
    }

    td {
      border-top: 0;
      padding: 11px 16px;
      font-size: 14px;
      vertical-align: middle;
    }

    tbody tr {
      background-color: $light-smoke;

      td:first-child {
        width: 18%;

        @include breakpoint(max-tablet-portrait) {
          width: auto;
        }
      }

      td:nth-child(2) {
        width: 20%;
        word-break: break-all;

        @include breakpoint(max-tablet-portrait) {
          width: auto;
        }
      }
    }
  }
}
</style>