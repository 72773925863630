<template>
    <TabbedPageTemplate testId="inquiries-page">
        <NdsCard :heading="{
            title: model.headline.value,
            type: 'h3',
            prefix: { prefix: 'far', iconName: 'comments' }
        }">
            <NdsText v-if="model.preamble.value" :text="model.preamble.value" isRichText tight />

            <div v-if="pending" class="d-flex align-items-center justify-content-center flex-fill">
                <NdsLoader />
            </div>
            <div v-else-if="failed">
                <Reloader @reload="getCreditInfo" />
            </div>

            <template v-else>
                <div v-if="showInquiries">
                    <NdsHeading :title="model.latestInquiriesSubTitle.value" type="h4" tight />
                    <NdsText :text="inquiriesDescriptionText" isRichText tight />
                    <div class="mb-3">
                        <ConsumerInquiries :consumer-inquiries="ctiConsumerInquiries.inquiries" />
                    </div>

                    <div v-if="showMinucInquiries" class="my-3">
                        <NdsHeading :title="model.otherInquiriesSubTitle.value" type="h4" tight />
                        <NdsText :text="minucInquiriesDescriptionText" isRichText tight />
                        <ConsumerInquiries :consumer-inquiries="ctiConsumerInquiries.inquiriesMinucOnly" />
                    </div>
                </div>

                <div v-else class="mb-3">
                    <NdsHeading :title="model.latestInquiriesSubTitle.value" type="h4" tight />
                    <NdsCard outlined>
                        <ZeroState icon="far fa-comments" text="Ingen förfrågan registrerad" />
                    </NdsCard>
                </div>
                <NdsFooter :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: UC" tight />
            </template>
        </NdsCard>
    </TabbedPageTemplate>
</template>

<script setup>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import TabbedPageTemplate from '../components/templates/TabbedPageTemplate.vue';
import Reloader from '../components/Reloader.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import { NdsLoader, NdsText, NdsCard, NdsHeading, NdsFooter } from '@nds/vue';
import ConsumerInquiries from '../components/ConsumerInquiries.vue';
import ZeroState from '../components/myinformation/ZeroState.vue';
import { formatDataInTemplate } from '../_helpers';
import helperTexts from "../../../json/helperTexts.json"

const props = defineProps({
    model: Object
});

const store = useStore();
const pending = computed(() => store.state.customerCreditInfoContex.pending);
const failed = computed(() => store.state.customerCreditInfoContex.failed);
const ctiConsumerInquiries = computed(() => store.state.customerCreditInfoContex.customerCreditInfo?.creditReport?.ctiConsumerInquires || {});
const showInquiries = computed(() => (ctiConsumerInquiries.value.inquiries || []).length > 0);
const showMinucInquiries = computed(() => (ctiConsumerInquiries.value.inquiriesMinucOnly || []).length > 0);
const numberOfInquiries = computed(() => (ctiConsumerInquiries.value.inquiries || []).length);
const numberOfMinucInquiries = computed(() => (ctiConsumerInquiries.value.inquiriesMinucOnly || []).length);

const inquiriesDescriptionText = computed(() =>
    formatDataInTemplate({
        template: props.model.latestInquiriesDescription.value,
        count: numberOfInquiries.value,
        options: { suffix: "st" }
    })
);

const minucInquiriesDescriptionText = computed(() =>
    formatDataInTemplate({
        template: props.model.otherInquiriesDescription.value,
        count: numberOfMinucInquiries.value,
        options: {
            singularLabel: helperTexts.inquiriesSingularLabel,
            pluralLabel: helperTexts.inquiriesPluralLabel
        }
    })
);

onMounted(() => {
    document.title = props.model.name || "Mina tjänster";
    if (!store.state.customerCreditInfoContex.loaded) {
        store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
    }
});

const getCreditInfo = () => {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
};
</script>