<template>
  <div class="dashboard" data-testid="dashboard-my-information-page-new">
    <div class="row">
      <div class="col-12 mt-2 mb-2 mobile-return-overview-btn d-md-none">
        <RouterLink to="/mina-tjanster" v-slot="{ href, navigate, isActive }" custom>
          <NdsLink :href="href" :active="isActive" @click="navigate" :prefix="{ prefix: 'far', iconName: 'home-alt' }">
            Översikt
          </NdsLink>
        </RouterLink>
      </div>
      <div v-if="pending" class="col-12">
        <NdsCard :heading="{ title: model.name, type: 'h3', prefix: { prefix: 'far', iconName: 'lightbulb' } }">
          <template #default>
            <div class="row">
              <div class="align-items-center justify-content-center d-flex flex-fill">
                <NdsLoader />
              </div>
            </div>
          </template>
        </NdsCard>
      </div>
      <div v-else-if="failed" class="col-12">
        <NdsCard :heading="{ title: model.name, type: 'h3', prefix: { prefix: 'far', iconName: 'lightbulb' } }">
          <template #default>
            <div class="row">
              <div class="col-12">
                <Reloader @reload="getCreditInfo" />
              </div>
            </div>
          </template>
        </NdsCard>
      </div>
      <template v-else>
        <div class="col-12">
          <div class="mb-3">
            <NdsCard :heading="{ title: model.name, type: 'h3', prefix: { prefix: 'far', iconName: 'lightbulb' } }"
              tight>
              <template #controls>
                <NdsButton @click="onOpenPdfModal" :prefix="{ prefix: 'far', iconName: 'file-pdf' }">
                  Skapa PDF
                </NdsButton>
              </template>
              <template #default>
                <div class="d-none d-lg-block">
                  <div class="col-7">
                    <div v-if="customerEssentials.displayCreditWatchProduct && model.descriptionHasCreditWatch"
                      v-html="model.descriptionHasCreditWatch.value" />
                    <div v-else-if="!customerEssentials.displayCreditWatchProduct && model.descriptionNoCreditWatch"
                      v-html="processedDescription" />
                    <NdsText v-if="fraudBarrier.FraudBarrierOn" type="p" tight text="Du har begärt att UC inte ska lämna ut några
                      uppgifter om dig, detta då din bedrägerispärr är aktiverad. Nedanstående uppgifter visas
                      inte för någon annan." />
                  </div>
                  <NdsTabs v-if="customerCreditInfo" v-model="tabModel" :tabs="tabs" @back="onBack"
                    @forward="onForward">
                    <template #general>
                      <General :report="customerCreditInfo" />
                    </template>
                    <template #income>
                      <Income :credit-report="customerCreditInfo.creditReport"
                        :income-bottom-text="model.incomeBottomText.value" />
                    </template>
                    <template #properties>
                      <Properties v-if="customerCreditInfo" :report="customerCreditInfo" />
                    </template>
                    <template #loans>
                      <Credits v-if="customerCreditInfo" :report="customerCreditInfo"
                        :intro-text="model.creditText.value" />
                    </template>
                    <template #remarks>
                      <RemarksTab v-if="customerCreditInfo" :report="customerCreditInfo"
                        :intro-text="model.remarksText.value" :model="model" />
                    </template>
                    <template #inquiries>
                      <Inquires v-if="customerCreditInfo" :report="customerCreditInfo"
                        :intro-text="model.inquiriesText.value" />
                    </template>
                  </NdsTabs>
                </div>
                <div class="d-lg-none row">
                  <div class="col-12">
                    <div v-if="customerEssentials.displayCreditWatchProduct && model.descriptionHasCreditWatch"
                      v-html="model.descriptionHasCreditWatch.value" />
                    <div v-else-if="!customerEssentials.displayCreditWatchProduct && model.descriptionNoCreditWatch"
                      v-html="processedDescription" />
                    <NdsText v-if="fraudBarrier.FraudBarrierOn" text="Du har begärt att UC inte ska lämna ut några
                      uppgifter om dig, detta då din bedrägerispärr är aktiverad. Nedanstående uppgifter visas
                      inte för någon annan." />
                  </div>
                </div>
              </template>
            </NdsCard>
          </div>
          <div class="d-lg-none mobile-cards-container">
            <GeneralMobile v-if="loaded" :report="customerCreditInfo" />
            <IncomeMobile v-if="loaded" :credit-report="customerCreditInfo.creditReport"
              :income-bottom-text="model.incomeBottomText.value" />
            <PropertiesMobile v-if="loaded" :report="customerCreditInfo" />
            <CreditsMobile v-if="loaded" :report="customerCreditInfo" :intro-text="model.creditText.value" />
            <CsnTabMobile v-if="loaded" :report="customerCreditInfo" :intro-text="model.csnText.value" />
            <RemarksTabMobile v-if="loaded" :report="customerCreditInfo" :intro-text="model.remarksText.value"
              :model="model" />
            <InquiriesMobile v-if="loaded" :report="customerCreditInfo" :intro-text="model.inquiriesText.value" />
          </div>
        </div>
      </template>
    </div>
    <div class="row mx-0 d-md-none mt-3">
      <div class="col-12">
        <a class="btn btn-enento-secondary w-100 bg-transparent" aria-controls="scroll to top" @click="onScrollToTop">
          <i class="far fa-long-arrow-alt-up fa-18 mr-3" />Ta mig till toppen av sidan</a>
      </div>
    </div>
  </div>
  <NdsConfirmModal v-bind="createPdfModal.props" v-model="createPdfModal.isOpen.value" @onConfirm="onConfirmPrintPdf"
    @onCancel="createPdfModal.closeModal" @onDismiss="createPdfModal.dismissModal">
    <template #body>
      <p>
        Klicka i de sektioner du vill ha med i din kreditupplysning i PDF-format.
      </p>
      <div class="pdfModal dashboard__table-column align-items-center">
        <div class="form-check mb-3 py-1">
          <input id="generalInfoCheck" v-model="filter.generalInfo" type="checkbox" disabled
            class="form-check-input disabled">
          <label class="form-check-label" for="generalInfoCheck">Allmänna uppgifter (obligatoriskt)</label>
        </div>
        <div class="form-check mb-3 py-1">
          <input id="incomeCheck" v-model="filter.incomeStatement" type="checkbox" class="form-check-input">
          <label class="form-check-label" for="incomeCheck">Inkomstuppgifter</label>
        </div>
        <div class="form-check mb-3 py-1">
          <input id="previousPropertiesCheck" v-model="filter.consumerProperties" type="checkbox"
            class="form-check-input">
          <label class="form-check-label" for="previousPropertiesCheck">Fastigheter</label>
        </div>
        <div class="form-check mb-3 py-1">
          <input id="creditsCheck" v-model="filter.credits" type="checkbox" class="form-check-input">
          <label class="form-check-label" for="creditsCheck">Kreditengagemang</label>
        </div>
        <div class="form-check mb-3 py-1">
          <input id="remarksCheck" v-model="filter.remarks" type="checkbox" disabled class="form-check-input disabled">
          <label class="form-check-label" for="remarksCheck">Anmärkningar (obligatoriskt)</label>
        </div>
        <div class="form-check mb-3 py-1">
          <input id="inquiriesCheck" v-model="filter.inquiries" type="checkbox" class="form-check-input">
          <label class="form-check-label" for="inquiriesCheck">Senaste frågor hos UC</label>
        </div>
      </div>
    </template>
  </NdsConfirmModal>
</template>
<script setup>
import $ from 'jquery';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { computed, onMounted, watch, reactive, toRaw, ref } from 'vue';
import { useWindowScroll } from '@vueuse/core';
import { NdsButton, NdsLink, NdsLoader, NdsCard, NdsConfirmModal, NdsTabs, NdsText, useConfirmModal, useTabs } from '@nds/vue';
import { FormatDateToSwedish } from '../_helpers.js';
import General from '../components/myinformation/General.vue';
import GeneralMobile from '../components/myinformation/GeneralMobile.vue';
import Income from '../components/myinformation/Income.vue';
import IncomeMobile from '../components/myinformation/IncomeMobile.vue';
import Inquires from '../components/myinformation/Inquiries.vue';
import InquiriesMobile from '../components/myinformation/InquiriesMobile.vue';
import Credits from '../components/myinformation/Credits.vue';
import CreditsMobile from '../components/myinformation/CreditsMobile.vue';
import CsnTabMobile from '../components/myinformation/CsnTabMobile.vue';
import Properties from '../components/myinformation/Properties.vue';
import PropertiesMobile from '../components/myinformation/PropertiesMobile.vue';
import RemarksTab from '../components/myinformation/RemarksTab.vue';
import RemarksTabMobile from '../components/myinformation/RemarksTabMobile.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex.js';
import { FRAUD_BARRIER_FETCH } from '../Scripts/store/modules/fraudBarrierContex.js';
import { postPrintPdf } from '../Scripts/providers/myServicesApiProviders.js'
import Reloader from '../components/Reloader.vue';
import { getRequestVerificationToken } from '../utils/authUtils.js';
import { createPdfErrorText } from '../components/modals/modalsUtils.js';
import { useEbbot } from '../utils/ebbotUtils.js';

const props = defineProps({
  model: Object
});
const store = useStore();
const route = useRoute();
const { y: windowY } = useWindowScroll({ behavior: 'smooth' });
const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const loaded = computed(() => store.state.customerCreditInfoContex.loaded);
const failed = computed(() => store.state.customerCreditInfoContex.failed);
const pending = computed(() => store.state.customerCreditInfoContex.pending);
const fraudBarrier = computed(() => store.state.fraudBarrierContex.fraudBarrier);
const processedDescription = computed(() => props.model.descriptionNoCreditWatch.value.replace('{0}', `<strong>${getPurchaseDateForProduct('Min Upplysning')}</strong>`));
const getDefaultFilter = () => structuredClone({
  generalInfo: true,
  remarks: true,
  inquiries: true,
  credits: true,
  incomeStatement: true,
  consumerProperties: true
});
const filter = reactive({
  generalInfo: true,
  remarks: true,
  inquiries: true,
  credits: true,
  incomeStatement: true,
  consumerProperties: true
});

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";

  // TODO remove jquery...
  const hash = window.location.hash;
  if (hash) {
    $('.nav-tabs a[href="' + hash + '"]').tab('show');
  }

  if (!store.state.customerCreditInfoContex.loaded) {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
  }
  if (!store.state.fraudBarrierContex.loaded) {
    store.dispatch(FRAUD_BARRIER_FETCH);
  }
});

const getCreditInfo = () => {
  store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
}

watch(route, () => {
  $('.nav-tabs a').removeClass('active');
  $('.nav-tabs a[href="#general-tab"]').addClass('active');
});

const getPurchaseDateForProduct = (productName) => {
  for (const product of customerEssentials.value.purchasedProductPackages) {
    if (product.productPackage.name === productName) {
      const date = new Date(parseInt(product.purchasedDate));
      return FormatDateToSwedish(date);
    }
  }
};

const tabs = ref([
  { id: 'general', name: 'Allmänt' },
  { id: 'income', name: 'Inkomst' },
  { id: 'properties', name: 'Fastigheter' },
  { id: 'loans', name: 'Krediter' },
  { id: 'remarks', name: 'Anmärkningar' },
  { id: 'inquiries', name: 'Förfrågningar' },
]);

const { tabModel, onBack, onForward } = useTabs({
  tabs,
  defaultTabId: "general",
});


const onScrollToTop = () => {
  windowY.value = 0;
}

const { showEbbot, hideEbbot } = useEbbot();

const createPdfModal = useConfirmModal({
  title: 'Skapa din kreditupplysning',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "file-pdf" },
  cancelBtnLabel: "Stäng",
  confirmBtnLabel: "Skapa PDF",
  testId: 'create-pdf-modal',
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const onOpenPdfModal = () => {
  createPdfModal.setConfirmBtnLabel('Skapa PDF');
  createPdfModal.setState({
    status: 'default',
  });
  createPdfModal.openModal();
}

const onConfirmPrintPdf = () => {
  if (createPdfModal.props.state.status === 'error') {
    return createPdfModal.setState({
      status: 'default',
    });
  }
  createPdfModal.setState({
    status: 'pending',
    message: 'Vänligen vänta i en stund medans vi behandlar dina uppgifter...'
  });
  const csrfToken = getRequestVerificationToken();
  postPrintPdf({ filter: toRaw(filter), csrfToken }).then(response => {
    // Create a blob URL for the PDF response
    if (response.statusText === 'OK') {
      try {
        const blobUrl = URL.createObjectURL(response.data);

        // Create a link element and simulate a click to trigger the download
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = "min_upplysning.pdf";
        link.click();

        // Open the downloaded PDF in a new tab or window
        window.open(blobUrl, "_blank");

        // Release the blob URL after the download is initiated
        URL.revokeObjectURL(blobUrl);

        filter.value = getDefaultFilter();
        createPdfModal.setState({
          status: 'complete',
          message: 'Din kreditupplysning är skapad!',
        });
        return;
      } catch (error) {
        console.log("Error in the print pdf:", error);
      }
    }
    filter.value = getDefaultFilter();
    createPdfModal.setConfirmBtnLabel('Försök igen');
    createPdfModal.setState({
      status: 'error',
      message: createPdfErrorText,
    });
    return;
  }).catch((error) => {
    console.log("Error in the print pdf:", error);
    filter.value = getDefaultFilter();
    createPdfModal.setConfirmBtnLabel('Försök igen');
    createPdfModal.setState({
      status: 'error',
      message: createPdfErrorText,
    });
    return;
  });
}

</script>
<style lang="scss">
@import "../../../styles/abstracts/_variables.scss";

.mobile-cards-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pdfModal {
  label {
    padding-left: 8px;
    color: #000;
  }

  .form-check-input {
    height: 24px;
    width: 24px;
    margin-top: 0;
    accent-color: $dark-ocean;

    &.disabled {
      opacity: 1 !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.text-small {
  font-size: 12px;
}
</style>
