export const useEbbot = () => {
    const showEbbot = () => {
        try {
            if (typeof window !== "undefined") {
                return window?.Ebbot.show();
            }
            console.log("Chatbot is not configured, on show");
        }
        catch {
            console.log("Chatbot is not configured, on show error");
        }
    }

    const hideEbbot = () => {
        try {
            if (typeof window !== "undefined") {
                return window?.Ebbot.hide();
            }
            console.log("Chatbot is not configured, on hide");
        }
        catch {
            console.log("Chatbot is not configured, on hide error");
        }
    }

    return { showEbbot, hideEbbot }
}