/**
 * Sets up the router that is used in View mode. When the site is loaded in
 * CMS editing mode, the whole page is always reloaded and the router only gets
 * the current page. The router will not be used for navigating between pages.
 */

import { createRouter, createWebHistory } from 'vue-router';
import { useStore } from 'vuex';
import { EPI_DATA_MODEL_UPDATE_MODEL_BY_URL } from './store/modules/epiDataModelContext';
import {
    APP_SHOW_SIDE_NAVIGATION,
    APP_HIDE_SIDE_NAVIGATION,
    APP_FETCH_MENU,
    APP_IS_ON_MY_SERVICES_OR_SETTINGS,
    APP_IS_NOT_ON_MY_SERVICES_OR_SETTINGS
} from './store/modules/appContext';
import ComponentSelector from '../components/ComponentSelector.vue';
import { getPathnameFromUrl } from '../components/checkout/checkoutUtils';

const router = createRouter({
    // Use the HTML HistoryAPI so the # isn't needed in the URL, and
    // Vue routing will work even when going directly to a URL.
    mode: 'history',
    history: createWebHistory(),
    routes: [
        {
            path: '/:pathMatch(.*)*',
            component: ComponentSelector
        }
    ],
    scrollBehavior() {
        return { top: 0 }
    }
});

router.beforeEach((to, _, next) => {
    const store = useStore();

    if (to.fullPath.includes('checkout')) {
        store.dispatch(APP_HIDE_SIDE_NAVIGATION);
    } else {
        store.dispatch(APP_SHOW_SIDE_NAVIGATION);
    }

    if (to.fullPath.includes('mina-installningar') || to.fullPath.includes('mina-tjanster')) {
        store.dispatch(APP_IS_ON_MY_SERVICES_OR_SETTINGS);
    } else {
        store.dispatch(APP_IS_NOT_ON_MY_SERVICES_OR_SETTINGS);
    }

    const url = store.state && store.state.epiDataModelContext.model.url ? getPathnameFromUrl(store.state.epiDataModelContext.model.url) : '/';
    if (url !== to.fullPath) {
        store.dispatch(EPI_DATA_MODEL_UPDATE_MODEL_BY_URL, to.fullPath);
    }

    if (to.query.mailbox) {
        const queryParams = { ...to.query };
        delete queryParams.mailbox;
        store.dispatch(APP_FETCH_MENU);
        return next({ path: to.path, query: queryParams });
    }

    next();
});

router.isReady().then(() => {
    window.addEventListener('pageshow', (event) => {
        if (event.persisted) {
            window.location.reload();
        }
    });
});

export default router;
