<template>
    <div class="mt-3">
        <NdsHeading :title="headline" type="h4" tight />

        <div v-if="hasCsn" class="d-flex justify-content-between mb-3">
            <div class="d-flex credit-summary-wrapper">
                <p class="d-flex flex-wrap m-0">
                    <span class="text-nowrap mr-1">{{ debtLabel }}</span>
                    <span class="color-ocean">{{ csnCreditSummary }}</span>
                </p>
            </div>
        </div>

        <div class="mb-4">
            <NdsText tight :text="preamble" isRichText />
        </div>

        <div class="d-none d-xl-block">
            <Csn v-if="creditReport.ctiCsnLoan" :cti-csn-loan="creditReport.ctiCsnLoan" />
        </div>
        <div class="d-block d-xl-none">
            <CsnMobile v-if="creditReport.ctiCsnLoan" :cti-csn-loan="creditReport.ctiCsnLoan" />
        </div>
        <NdsCard v-if="!hasCsn" tight outlined>
            <ZeroState icon="far fa-graduation-cap" text="Inget CSN-lån registrerat" />
        </NdsCard>
        <NdsFooter class="mt-3 p-0" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: CSN" tight />
    </div>
</template>
<script setup>
import { computed } from 'vue';
import { NdsCard, NdsHeading, NdsFooter, NdsText } from '@nds/vue';
import { formatToSek } from '../_helpers.js';
import Csn from '../components/Csn.vue';
import CsnMobile from '../components/CsnMobile.vue';
import ZeroState from "../components/myinformation/ZeroState.vue";

const props = defineProps({
    model: Object,
    report: Object,
});


const modelItem = props?.model?.creditContentArea?.expandedValue?.[5];
const headline = computed(() => modelItem?.headline.value);
const debtLabel = computed(() => modelItem?.debtLabel.value);
const preamble = computed(() => modelItem?.preamble.value);
const creditReport = computed(() => props.report.creditReport);

const hasCsn = computed(() => {
    const csnLoan = creditReport.value?.ctiCsnLoan;

    return csnLoan?.hasInstallmentLoan ||
        csnLoan?.hasInstallmentLoan2022 ||
        csnLoan?.hasStudentLoan ||
        csnLoan?.hasStudentMeans
});

const csnCreditSummary = computed(() => {
    const loan = creditReport.value.ctiCsnLoan?.csnLoanElement?.[0];
    if (!loan) return formatToSek(0);

    const loanTypes = [
        loan.installmentLoan?.creditAmount,
        loan.installmentLoan2022?.creditAmount,
        loan.studentLoan?.creditAmount,
        loan.studentMeans?.creditAmount
    ];

    const loanSummary = loanTypes.reduce((sum, amount) => sum + (amount || 0), 0);

    return formatToSek(loanSummary);
});

</script>
<style scoped lang="scss">
.credit-summary-wrapper {
    flex: 1;
}
</style>