<template>
  <div>
    <div v-if="customerEventsPending" class="align-items-center justify-content-center d-flex">
      <NdsLoader />
    </div>
    <Reloader v-if="customerEventsFailed" @reload="reFetchCustomerEvents" />
    <div v-if="customerEventsLoaded">
      <div v-if="customerEvents.length > 0" class="row flex-column">
        <div class="col-12">
          <div v-if="searchEnabled" class="wrapper">
            <i class="far fa-search fa-fw input-icon" />
            <input v-model="filterInputModel" type="text" class="form-control mb-3 w-md-75"
              placeholder="Sök datum, händelse eller aktör." @keyup="filterResult">
          </div>
        </div>
        <NdsAccordion v-if="paginatedEvents.length > 0" v-model="activeAccordionId" :accordions="paginatedEvents">
          <template #tertiarytitle="{ accordion }">
            <div class="tertiary-title-wrapper">
              <NdsStatusBadge v-if="accordion.isWithinSevenWeeks" size="small" variant="excellent">Ny!
              </NdsStatusBadge>
              {{ accordion.decisionDate }}
            </div>
          </template>
          <template v-for="(event, index) in filteredCustomerEvents" #[event.id] :key="index">
            <NdsText class="event-text" :text="event.bodyText" isRichText />
          </template>
        </NdsAccordion>
        <NdsCard v-else tight outlined>
          <ZeroState icon="far fa-check" text="Inga händelser hittades" />
        </NdsCard>
        <div v-if="totalPages > 1 && pagingEnabled" class="mt-4">
          <NdsPagination :length="totalPages" v-model="currentPage" />
        </div>
      </div>
      <NdsCard v-else tight outlined>
        <ZeroState icon="far fa-check" text="Du har inga händelser" />
      </NdsCard>
    </div>
    <div
      v-if="showMoreButton && customerEvents !== null && customerEvents.length > 0 && linkToDetailsPageOnIdProtectionPage"
      class="mt-3 text-center">
      <RouterLink :to="linkToDetailsPageOnIdProtectionPage" class="btn btn-enento-text">
        Visa alla händelser
      </RouterLink>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { FormatDate, timestampIsWithinSevenWeeks } from '../_helpers.js';
import { NdsAccordion, NdsCard, NdsPagination, NdsText, NdsLoader, NdsStatusBadge, usePagination } from '@nds/vue';
import ZeroState from "../components/myinformation/ZeroState.vue";
import Reloader from '../components/Reloader.vue';
import { CUSTOMER_INFO_FETCH } from '../Scripts/store/modules/customerInfoContex';

const props = defineProps({
  itemsPerPage: Number,
  pagingEnabled: Boolean,
  searchEnabled: Boolean,
  showMoreButton: Boolean
});

const store = useStore();

const customerEvents = computed(() => store.state.customerInfoContex.customerEvents);
const customerEventsLoaded = computed(() => store.state.customerInfoContex.loaded);
const customerEventsFailed = computed(() => store.state.customerInfoContex.failed);
const customerEventsPending = computed(() => store.state.customerInfoContex.pending);
const linkToDetailsPageOnIdProtectionPage = computed(() => {
  const url = store.state.appContext.menu.myServicesMenu.find((item) => item.isPurchased && item.productType === store.state.appContext.menu.productTypes?.IdProtection)?.url;
  if (url) {
    return `${url}/?all=true`
  }
  return "";
})

const includesFilter = (text, filter) =>
  text?.toString().toLowerCase().includes(filter.toLowerCase()) ?? false;

const formatEventForAccordion = (event = {}) => ({
  id: event.eventId ?? null,
  title: event.creditRating?.name ?? event.creditRatingAttempt ?? null,
  subtitle: event.eventInfo?.title ?? null,
  bodyText: event.eventInfo?.information ?? null,
  decisionDate: event.decisionDate ? FormatDate(event.decisionDate) : null,
  isWithinSevenWeeks: event.notificationDate ? timestampIsWithinSevenWeeks(event.notificationDate) : false,
});

const filterInputModel = ref('');

const filteredCustomerEvents = computed(() => {

  const filter = filterInputModel.value.toLowerCase().trim();

  if (!filter) {
    return store.state.customerInfoContex.customerEvents.map(event => (formatEventForAccordion(event)));
  }

  return store.state.customerInfoContex.customerEvents
    .filter(event => {
      return (
        includesFilter(event.creditRating?.name, filter) ||
        includesFilter(event.creditRatingAttempt?.name, filter) ||
        includesFilter(event.eventInfo.title, filter) ||
        includesFilter(FormatDate(event.decisionDate), filter)
      );
    })
    .map(event => formatEventForAccordion(event));
});
const activeAccordionId = ref([]);
const {
  currentPage,
  totalPages,
  paginatedItems: paginatedEvents,
} = usePagination(filteredCustomerEvents, props.itemsPerPage);

console.log(paginatedEvents)

const reFetchCustomerEvents = () => {
  // TODO investigate if we should refetch here
  store.dispatch(CUSTOMER_INFO_FETCH);
}

</script>
<style scoped lang="scss">
.wrapper {
  position: relative;
}

.input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.form-control {
  padding-left: 30px;
}

.event-text {
  padding-top: 0.75rem;
}

.tertiary-title-wrapper {
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
</style>