<template>
  <div class="dashboard" data-testid="dashboard-page">
    <div class="DashboardPage row" scoped>
      <div class="col-12" data-testid="heading-welcome">
        <NdsHeading :title="`Välkommen ${customerEssentials.customerFullName}`" type="h2" />
      </div>
      <div class="col-lg-6 col-12">
        <div v-if="customerEssentials.displayIdProtectionProduct ||
          customerEssentials.displayCreditWatchProduct ||
          customerEssentials.displayDigitalMailbox" class="mb-3">
          <NdsCard :heading="{ title: 'Händelser', type: 'h3' }" tight>
            <template #default>
              <div class="eventlist p-0">
                <ul class="mb-0">
                  <RouterLink v-if="purchasedMenuItems.idProtection" :to="purchasedMenuItems.idProtection.url"
                    class="eventlist__item input-group-text position-relative border-0">
                    <li>
                      <i class="far fa-user-shield fa-fw" />
                      <span class="position-relative">ID-Skydd:
                        <span class="font-weight-medium">{{ recentCustomerEventsCount }} nya händelser</span>
                      </span>
                      <i class="far fa-chevron-right" />
                    </li>
                  </RouterLink>
                  <RouterLink v-if="purchasedMenuItems.creditWatch" :to="purchasedMenuItems.creditWatch.url"
                    :class="customerCreditNotifications.length > 0 ? 'not-read' : ''"
                    class="eventlist__item input-group-text position-relative border-0">
                    <li>
                      <i class="fas fa-analytics fa-fw" />
                      <span class="position-relative">Kreditkollen:
                        <span v-if="customerCreditNotifications.length > 0" class="font-weight-medium">{{
                          customerCreditNotifications.length }} nya händelser</span>
                        <span v-else class="font-weight-medium">0 nya händelser</span>
                      </span>
                      <i class="far fa-chevron-right" />
                    </li>
                  </RouterLink>
                  <RouterLink v-if="purchasedMenuItems.digitalMailbox" :to="purchasedMenuItems.digitalMailbox.url"
                    :class="customerUnreadMails > 0 ? 'not-read' : ''"
                    class="eventlist__item input-group-text position-relative border-0">
                    <li>
                      <i class="far fa-envelope fa-fw" />
                      <span class="position-relative">Digital Brevlåda:
                        <span class="font-weight-medium">
                          {{ customerUnreadMails }} nya händelser
                        </span>
                      </span> <i class="far fa-chevron-right" />
                    </li>
                  </RouterLink>
                </ul>
              </div>
            </template>
          </NdsCard>
        </div>
        <div class="mb-3">
          <NdsCard :heading="{ title: 'Mina Tjänster', type: 'h3' }" tight>
            <template #controls>
              <RouterLink to="/mina-installningar#payments-tab" v-slot="{ href, navigate, isActive }" custom>
                <NdsButton :href="href" :active="isActive" size="small" variant="text" width="full" @click="navigate">
                  Gå till betalningar
                </NdsButton>
              </RouterLink>
            </template>
            <template #default>
              <div class="row mx-0 my-2" data-testid="dashboard-product-link-ball-cards">
                <div v-for="(menuItem, index) in payedForProducts" :key="`dashboard_ball_key_${index}`"
                  class="col-6 d-flex justify-content-center" :class="index > 1 && 'pt-4 pt-md-4'">
                  <DashboardBallButton v-bind="menuItem" :productEndDate="menuItem.productType === 'MyInformation' ? getEndDateForProduct({
                    productName: 'Min Upplysning',
                    purchasedProductPackages: customerEssentials.purchasedProductPackages
                  }) : ''" />
                </div>
              </div>
              <div class="col p-0 no-gutters row-sm">
                <div class="col-12 mb-2">
                  <NdsHeading title="Gratistjänster" type="h4" class="pb-0" />
                  <template v-for="(menuItem, index) in freeProducts" :key="`dashboard_ball_key_${index}`">
                    <DashboardFreeProductButton v-bind="menuItem" />
                  </template>
                </div>
                <div class="col-12 mb-2">
                  <NdsHeading title="Engångsköp" type="h4" class="pb-0" />
                  <NdsButton href="/forlustanmalan" variant="text"
                    :prefix="{ prefix: 'far', iconName: 'exclamation-circle' }"
                    data-testid="dashboard-one-time-purchase-loss-notification">
                    Förlustanmälan
                  </NdsButton>
                </div>
              </div>
            </template>
          </NdsCard>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <NdsCardSpacing :gap="3" direction="column" center>
          <NdsCard v-if="purchasedMenuItems.idProtection"
            :heading="{ title: 'UC Bevakning', type: 'h3', prefix: { prefix: 'far', iconName: 'eye' } }">
            <template #controls>
              <RouterLink :to="purchasedMenuItems.idProtection.url" v-slot="{ href, navigate, isActive }" custom>
                <NdsButton :href="href" :active="isActive" size="small" variant="text" width="full" @click="navigate">
                  Gå till ID-Skydd
                </NdsButton>
              </RouterLink>
            </template>
            <template #default>
              <CustomerEvents :items-per-page="5" :paging-enabled="false" :search-enabled="false" showMoreButton />
            </template>
          </NdsCard>
          <NdsCard v-if="purchasedMenuItems.creditWatch"
            :heading="{ title: 'UC Score', type: 'h3', prefix: { prefix: 'fas', iconName: 'analytics' } }"
            variant="primary" :backgroundImageSrc="creditWatchBackgroundImageLoggedInSrc">
            <template #controls>
              <RouterLink :to="purchasedMenuItems.creditWatch.url" v-slot="{ href, navigate, isActive }" custom>
                <NdsButton :href="href" :active="isActive" size="small" variant="text" onBg="dark" width="full"
                  @click="navigate">
                  Gå till Kreditkollen
                </NdsButton>
              </RouterLink>
            </template>
            <template #default>
              <UcScore v-if="showUcScore" :marker-radius="15" :height="260" :report="customerCreditInfo" />
              <div v-else class="uc-score-not-loaded">
                <RouterLink v-slot="{ href, navigate, isActive }" :to="creditWatchRelativeUrl" custom>
                  <NdsButton :href="href" :active="isActive" variant="secondary" onBg="dark" size="large" width="full"
                    class="uc-score-link" @click="navigate">
                    {{ props?.model?.lockedCreditWatch?.linkTextLoggedIn?.value }}
                  </NdsButton>
                </RouterLink>
              </div>
            </template>
          </NdsCard>
          <template
            v-if="!customerEssentials.displayIdProtectionProduct && !customerEssentials.displayCreditWatchProduct">
            <ProductLockedBlock v-if="!customerEssentials.displayCreditWatchProduct" :model="model.lockedCreditWatch"
              :prefix="{ prefix: 'fas', iconName: 'analytics' }" />
            <ProductLockedBlock v-if="!customerEssentials.displayIdProtectionProduct" :model="model.lockedIdProtection"
              :prefix="{ prefix: 'far', iconName: 'user-shield' }" />
          </template>
          <template v-else>
            <ProductLockedBlock v-if="!customerEssentials.displayIdProtectionProduct" :model="model.lockedIdProtection"
              :prefix="{ prefix: 'far', iconName: 'user-shield' }" />
            <ProductLockedBlock v-if="!customerEssentials.displayCreditWatchProduct" :model="model.lockedCreditWatch"
              :prefix="{ prefix: 'fas', iconName: 'analytics' }" />
          </template>
        </NdsCardSpacing>
      </div>
    </div>
  </div>
  <NdsConfirmModal v-bind="errorModal.props" v-model="errorModal.isOpen.value" @onConfirm="onCloseErrorModal"
    @onCancel="onCloseErrorModal" @onDismiss="errorModal.dismissModal" />
</template>
<script setup>
import { computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { NdsButton, NdsConfirmModal, NdsCard, NdsHeading, NdsCardSpacing, useConfirmModal } from '@nds/vue';
import DashboardBallButton from '../components/buttons/DashboardBallButton.vue';
import DashboardFreeProductButton from '../components/buttons/DashboardFreeProductButton.vue';
import UcScore from '../components/charts/UcScore.vue';
import ProductLockedBlock from '../components/ProductLockedBlock.vue';
import CustomerEvents from '../components/CustomerEvents.vue';
import { getEndDateForProduct } from '../utils/productUtils';
import { CUSTOMER_CREDIT_NOTIFICATIONS_FETCH } from '../Scripts/store/modules/customerCreditNotificationsContex';
import { getPathnameFromUrl } from '../components/checkout/checkoutUtils';
import { CUSTOMER_CREDIT_INFO_ON_CLOSE_ERROR_MODAL } from '../Scripts/store/modules/customerCreditInfoContex';
import { creditReportError } from '../components/modals/modalsUtils';
import { useEbbot } from '../utils/ebbotUtils.js';

const props = defineProps(['model']);
const store = useStore();

const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const customerUnreadMails = computed(() => store.state.customerInfoContex.customerUnreadMails);
const customerCreditInfo = computed(() => store.state.customerCreditInfoContex.customerCreditInfo);
const showErrorModal = computed(() => store.state.customerCreditInfoContex.showErrorModal);
const customerCreditNotifications = computed(() => store.state.customerCreditNotificationsContex.customerCreditNotifications);
const recentCustomerEventsCount = computed(() => store.state.customerInfoContex?.recentCustomerEventsCount);

const payedForProducts = computed(() => store.state.appContext.menu.myServicesMenu.filter((item) => !item.isFreeProduct));
const freeProducts = computed(() => store.state.appContext.menu.myServicesMenu.filter((item) => item.isFreeProduct));
const purchasedMenuItems = computed(() => ({
  idProtection: store.state.appContext.menu.myServicesMenu.find((item) => item.isPurchased && item.productType === store.state.appContext.menu.productTypes?.IdProtection),
  creditWatch: store.state.appContext.menu.myServicesMenu.find((item) => item.isPurchased && item.productType === store.state.appContext.menu.productTypes?.CreditWatch),
  digitalMailbox: store.state.appContext.menu.myServicesMenu.find((item) => item.isPurchased && item.productType === store.state.appContext.menu.productTypes?.DigitalMailbox),
}));

const creditWatchRelativeUrl = computed(() => props.model.lockedCreditWatch?.linkLoggedIn?.value?.url ? getPathnameFromUrl(props.model.lockedCreditWatch?.linkLoggedIn?.value?.url) : '');
const showUcScore = computed(() => customerCreditInfo.value.creditReport?.creditwatchScoreList && store.state.customerCreditInfoContex.loaded && !store.state.customerCreditInfoContex.failed);
const creditWatchBackgroundImageLoggedInSrc = computed(() => props.model.lockedCreditWatch?.loggedInBackgroundImage?.value?.url && !showUcScore.value ? props.model.lockedCreditWatch?.loggedInBackgroundImage?.value?.url : '');

watch(showErrorModal, (value) => {
  if (value) {
    errorModal.openModal();
  }
})

const { showEbbot, hideEbbot } = useEbbot();

const errorModal = useConfirmModal({
  ...creditReportError,
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const onCloseErrorModal = () => {
  errorModal.closeModal();
  store.dispatch(CUSTOMER_CREDIT_INFO_ON_CLOSE_ERROR_MODAL);
}

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  store.dispatch(CUSTOMER_CREDIT_NOTIFICATIONS_FETCH);
  if (store.state.customerCreditInfoContex.showErrorModal) {
    errorModal.openModal();
  }
});
</script>
<style scoped lang="scss">
@import "../../../styles/abstracts/_variables.scss";
@import "../../../styles/abstracts/_breakpoints.scss";
@import "../../../styles/abstracts/_mixins.scss";

.uc-score-not-loaded {
  min-height: 228px;
  position: relative;

  .uc-score-link {
    position: absolute;
    bottom: 0;
  }
}

.eventlist {
  ul {
    list-style-type: none;
    padding-left: 0;

    a {
      background-color: transparent;
      color: $black;
      padding: 0.75rem;

      @include attention() {
        background-color: $light-smoke;
        text-decoration: none;
      }

      &.not-read {
        background-color: $rodeo-dust;

        @include attention() {
          background-color: $rodeo-dust-hover;
        }
      }

      li {
        span {
          padding: 4px 6px;
        }

        i {
          color: $orangegold;

          &:first-child {
            margin-right: 5px;
          }

          &:last-child {
            position: absolute;
            top: calc(50% - 8px);
            right: 16px;
            color: $dark-ocean;
          }
        }
      }
    }
  }
}
</style>
