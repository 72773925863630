<template>
    <div v-if="isPurchased || !canBePurchased">
        <RouterLink :to="url" v-slot="{ href, navigate, isActive }" custom>
            <NdsButton :href="href" :active="isActive" variant="text"
            :data-testid="`dashboard-gratistjanster-${dataTestId}-btn`"
            :prefix="{ prefix: 'far', iconName }" @click="navigate">
            {{ title }}
            </NdsButton>
        </RouterLink>
    </div>
    <div v-else>
    <NdsButton :href="productInfoPageUrl" variant="text" :prefix="{ prefix: 'far', iconName }"
        :data-testid="`dashboard-gratistjanster-${dataTestId}-btn`">
        Registrera {{ title }}
    </NdsButton>
    </div>
</template>
<script setup>
import { computed } from 'vue';
import { NdsButton } from '@nds/vue';

const props = defineProps({
    name: String,
    title: String,
    url: String,
    icon: String,
    iconContainer: String,
    isFreeProduct: Boolean,
    isPurchased: Boolean,
    canBePurchased: Boolean,
    productType: String,
    productInfoPageUrl: String,
    dataTestId: String,
});

const iconName = computed(() => {
    const parts = props.icon.split(' ');
    const iconPart = parts[parts.length - 1];
    
    // Match "fa-" followed by any characters
    const match = iconPart.match(/^fa-(.+)$/);
    return match ? match[1] : iconPart;
})
</script>
