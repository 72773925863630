import axios from 'axios';

const basePath = '/frontendapi';
const essentialCustomerInfoPath = `${basePath}/essentialcustomerinfo`;
const fraudBarrierStatusPath = `${basePath}/getfraudbarrierstatus`;
const getMenuPath = `${basePath}/getMenu`;
const getOwnedUmbrellasPath = `${basePath}/getownedumbrellas`;
const getUmbrellaMembersPath = `${basePath}/getumbrellamembers`;
const getProductPackagePath = `${basePath}/getproductpackage`;
const getGetDigitalMailBoxMessagesPath = `${basePath}/getdigitalmailboxmessages`;
const getNumberOfNewItemsPath = `${basePath}/getnumberofnewitems`;
const postSetDigitalMailBoxActivePath = `${basePath}/setactive`;

const get = ({ url, headers, params }) => {
    return axios({
        method: 'get',
        url,
        params,
        headers,
    });
};

const post = ({ url, data, headers }) => {
    return axios({
        method: 'post',
        url,
        data,
        headers
    });
};

export const postProductPackage = ({ productPackageId, relatedProductsIds }) => post(
    {
        url: getProductPackagePath,
        data: {
            productPackageId,
            relatedProductsIds,
        },
        headers: { Accept: 'application/json' }
    });
export const postSetDigitalMailBoxActive = ({ setActive }) => post(
    {
        url: postSetDigitalMailBoxActivePath,
        data: {
            setActive,
        },
        headers: { Accept: 'application/json' }
    });
export const getEssentialCustomerInfo = ({ reFetch, checkCancellableProducts }) => get({ url: essentialCustomerInfoPath, params: { reFetch, checkCancellableProducts }, headers: { Accept: 'application/json' } });
export const getFraudBarrierStatus = () => get({ url: fraudBarrierStatusPath, headers: { Accept: 'application/json' } });
export const getMenu = () => get({ url: getMenuPath, headers: { Accept: 'application/json' } });
export const getOwnedUmbrellas = () => get({ url: getOwnedUmbrellasPath, headers: { Accept: 'application/json' } });
export const getUmbrellaMembers = ({ umbrellaProductId }) => get({ url: getUmbrellaMembersPath, params: { umbrellaProductId }, headers: { Accept: 'application/json' } });
export const getGetDigitalMailBoxMessages = () => get({ url: getGetDigitalMailBoxMessagesPath, headers: { Accept: 'application/json' } });
export const getGetNumberOfNewMails = () => get({ url: getNumberOfNewItemsPath, headers: { Accept: 'application/json' } });