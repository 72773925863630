<template>
  <div data-testid="invite-member-form">
    <NdsHeading title="Bjud in ny person" type="h4" tight />
    <NdsText type="p" tight text="Inbjudan skickas via e-post." />
    <div class="invite-member-form-container">
      <div class="form-group">
        <label for="email">{{ formModel.email.label }}</label>
        <input id="email" v-model="v$.email.$model" type="email" class="form-control"
          :placeholder="formModel.email.placeholder" :class="v$.email.$errors.length > 0 ? 'is-invalid' : ''"
          :aria-label="formModel.email.ariaLabel">
        <template v-if="v$.email.$errors.length > 0">
          <small v-if="v$.email.email.$invalid" class="is-invalid">{{ v$.email.email.$message }}</small>
          <small v-else-if="v$.email.required.$invalid" class="is-invalid">{{ v$.email.required.$message }}</small>
        </template>
      </div>
      <div class="form-group">

        <label for="name">{{ formModel.name.label }}</label>
        <input id="name" v-model="v$.name.$model" type="text" class="form-control"
          :placeholder="formModel.name.placeholder" :class="v$.name.$errors.length > 0 ? 'is-invalid' : ''"
          :aria-label="formModel.name.ariaLabel">
        <template v-if="v$.name.$errors.length > 0">
          <small v-if="v$.name.required.$invalid" class="is-invalid">{{ v$.name.required.$message }}</small>
          <small v-else-if="v$.name.validName.$invalid" class="is-invalid">
            {{ v$.name.validName.$message }}
          </small>
          <small v-else-if="v$.name.duplicate.$invalid" class="is-invalid">
            {{ v$.name.duplicate.$message }}
          </small>
          <small v-else-if="v$.name.maxLength.$invalid" class="is-invalid">{{ v$.name.maxLength.$message }}</small>
        </template>
      </div>
      <div>
        <NdsButton theme="enento" variant="primary" @click="sendGroupInvite()" :width="isMobile ? 'full' : ''">
          Bjud in medlem
        </NdsButton>
      </div>
    </div>
  </div>
  <NdsConfirmModal v-bind="invitationSentModal.props" v-model="invitationSentModal.isOpen.value"
    @onConfirm="invitationSentModal.closeModal" @onCancel="invitationSentModal.closeModal" />
  <NdsConfirmModal v-bind="invateSentErrorModal.props" v-model="invateSentErrorModal.isOpen.value"
    @onConfirm="invateSentErrorModal.closeModal" @onCancel="invateSentErrorModal.closeModal"
    @onDismiss="invateSentErrorModal.dismissModal">
    <template #body>
      Kontrollera om ett mail redan har skickats till <strong>{{ form.email }}</strong>
    </template>
  </NdsConfirmModal>
  <NdsConfirmModal v-bind="errorModal.props" v-model="errorModal.isOpen.value" @onConfirm="errorModal.closeModal"
    @onCancel="errorModal.closeModal" @onDismiss="errorModal.dismissModal" />
</template>
<script setup>
import { useVuelidate } from '@vuelidate/core';
import { reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { NdsButton, NdsConfirmModal, NdsHeading, NdsText, useConfirmModal } from '@nds/vue';
import { required, email, helpers, maxLength, not } from '@vuelidate/validators';
import { isValidName } from '../utils/validators';
import { postSendGroupInvite } from '../Scripts/providers/customerApiProvider';
import helperTexts from '../../../json/helperTexts.json';
import { getRequestVerificationToken } from '../utils/authUtils';
import { UMBRELLA_MEMBERS_FETCH } from '../Scripts/store/modules/umbrellaMembersContext';
import { useSiteBreakpoints } from '../utils/breakpointsUtils';
import { defaultGenericError } from '../components/modals/modalsUtils';
import { useEbbot } from '../utils/ebbotUtils.js';

const props = defineProps({
  groupId: Number,
  invitations: Array,
});

const store = useStore();

const invitedNames = computed(() => props.invitations.map(({ name }) => name.trim()));

const { isMobile } = useSiteBreakpoints();

const formModel = {
  email: {
    label: 'E-post',
    placeholder: helperTexts.placeholderEmail,
    ariaLabel: 'E-post fält',
  },
  name: {
    label: 'Namn',
    ariaLabel: 'Namn fält',
  },
};

const form = reactive({
  email: '',
  name: ''
});

const isDuplicate = (value) => {
  return invitedNames.value.includes(value.trim())
};

const rules = computed(() => {
  return {
    email: {
      required: helpers.withMessage(helperTexts.validationMessageRequired, required),
      email: helpers.withMessage(helperTexts.validationMessageEmail, email),
    },
    name: {
      validName: {
        $validator: isValidName,
        $message: helperTexts.validationMessageName,
      },
      duplicate: helpers.withMessage(helperTexts.validationMessageUniqie, not(isDuplicate)),
      maxLength: helpers.withMessage(helperTexts.validationMessageNameMaxLegth, maxLength(30)),
      required: helpers.withMessage(helperTexts.validationMessageRequired, required),
    },
  }
});

const v$ = useVuelidate(rules, form);

const { showEbbot, hideEbbot } = useEbbot();

const invitationSentModal = useConfirmModal({
  title: 'Inbjudan skickad',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "check-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: 'Inbjudan är skickad! Om mottagaren inte får ett mejl, be dem kolla sin skräppost.',
    isRichText: false,
  },
  testId: 'recently-terminated-product-modal',
  /* TODO: reapply callback functions when bug is fixed on MIN-3359. Also add @onDismiss="invitationSentModal.dismissModal" on modal in template.
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot, */
});

const errorModal = useConfirmModal({
  ...defaultGenericError,
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const invateSentErrorModal = useConfirmModal({
  title: 'Inbjudan kunde inte skickas',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const sendGroupInvite = () => {
  v$.value.$validate();
  if (!v$.value.$error) {
    const csrfToken = getRequestVerificationToken();
    postSendGroupInvite({ groupId: props.groupId, name: form.name, email: form.email, csrfToken }).then((response) => {
      if (response.data?.success) {
        store.dispatch(UMBRELLA_MEMBERS_FETCH, { umbrellaProductId: store.state.customerInfoContex.customerEssentials.customerProducts.umbrellaProductId });
        return invitationSentModal.openModal();
      }
      return invateSentErrorModal.openModal();
    }).catch(() => {
      return errorModal.openModal();
    })
  }
}

</script>
<style lang="scss" scoped>
@import "../../../styles/abstracts/_breakpoints.scss";

.invite-member-form-container {
  max-width: 50%;

  @include breakpoint(max-tablet-landscape) {
    max-width: 100%;
  }

  .form-group label {
    color: #333333;
    font-size: 14px;
    font-weight: 700;
  }
}
</style>
