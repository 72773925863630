<template>
    <TabbedPageTemplate testId="credit-page">
        <NdsCard tight>
            <div class="credit-page-wrapper">
                <NdsHeading :title="model.headline.value" type="h3" :prefix="{ prefix: 'far', iconName: 'sack-dollar' }"
                    tight />
                <NdsText v-if="model.preamble.value" :text="model.preamble.value" isRichText tight />

                <div v-if="pending" class="d-flex align-items-center justify-content-center flex-fill">
                    <NdsLoader />
                </div>
                <div v-else-if="failed">
                    <Reloader @reload="getCreditInfo" />
                </div>

                <template v-else>
                    <NdsHeading :title="model.summarySubTitle.value" type="h4" tight />

                    <div class="d-flex flex-column flex-md-row justify-content-between mb-3">
                        <div class="d-flex flex-column mb-4 mb-md-0">
                            <div class="d-flex justify-content-between">
                                <div>Totalt beviljad kredit:</div>
                                <div class="font-weight-medium ml-4 credit-summary-value"
                                    data-testid="credit-watch-credits-total-granted-amount">
                                    {{ totalGrantedCredits }}
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>Totalt utnyttjat kredit:</div>
                                <div class="color-ocean font-weight-medium ml-4 credit-summary-value"
                                    data-testid="credit-watch-credits-total-used-amount">
                                    {{ totalUsedCredits }}
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column">
                            <div class="d-flex justify-content-between">
                                <div>Antal krediter:</div>
                                <div class="font-weight-medium ml-4 credit-summary-value"
                                    data-testid="credit-watch-credits-nr-of-credits">
                                    {{ totalNumberOfCredits }}
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>Antal kreditgivare:</div>
                                <div class="font-weight-medium ml-4 credit-summary-value"
                                    data-testid="credit-watch-credits-nr-of-creditors">
                                    {{ totalNumberOfCreditors }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <NdsFooter class="p-0" :prefix="{ prefix: 'far', iconName: 'book' }"
                        text="Källa: Kreditgivare i Sverige" tight />
                </template>
            </div>

            <div v-if="!pending && !failed" class="tabs-wrapper">
                <NdsTabs v-model="tabModel" :tabs="tabs" @back="onBack" @forward="onForward">
                    <template #unsecuredLoanTab>
                        <UnsecuredLoansV2 :report="store.state.customerCreditInfoContex.customerCreditInfo"
                            :model="props.model" />
                    </template>
                    <template #downPaymentTab>
                        <DownPaymentCreditV2 :report="store.state.customerCreditInfoContex.customerCreditInfo"
                            :model="props.model" />
                    </template>
                    <template #creditCardTab>
                        <CreditCardV2 :report="store.state.customerCreditInfoContex.customerCreditInfo"
                            :model="props.model" />
                    </template>
                    <template #propertyTab>
                        <PropertyCredits :report="store.state.customerCreditInfoContex.customerCreditInfo"
                            :model="props.model" />
                    </template>
                    <template #tenantOwnershipTab>
                        <TenantOwnershipCredits :report="store.state.customerCreditInfoContex.customerCreditInfo"
                            :model="props.model" />
                    </template>
                    <template #studentLoanTab>
                        <CsnTab :report="store.state.customerCreditInfoContex.customerCreditInfo" :model="props.model" />
                    </template>
                </NdsTabs>
            </div>
        </NdsCard>
    </TabbedPageTemplate>
</template>

<script setup lang="js">
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import TabbedPageTemplate from '../components/templates/TabbedPageTemplate.vue';
import Reloader from '../components/Reloader.vue';
import { CUSTOMER_CREDIT_INFO_FETCH } from '../Scripts/store/modules/customerCreditInfoContex';
import { NdsLoader, NdsText, NdsCard, NdsHeading, NdsFooter, NdsTabs, useTabs } from '@nds/vue';
import { formatToSek, formatToAmount } from '../_helpers.js';
import CreditCardV2 from '../components/CreditCardV2.vue';
import DownPaymentCreditV2 from '../components/DownPaymentCreditV2.vue';
import UnsecuredLoansV2 from '../components/UnsecuredLoansV2.vue';
import TenantOwnershipCredits from '../components/TenantOwnershipCredits.vue';
import PropertyCredits from '../components/PropertyCredits.vue';
import CsnTab from '../components/CsnTab.vue';

const props = defineProps({
    model: Object
});

const store = useStore();
const pending = computed(() => store.state.customerCreditInfoContex.pending);
const failed = computed(() => store.state.customerCreditInfoContex.failed);
const creditReport = computed(() => store.state.customerCreditInfoContex.customerCreditInfo.creditReport);

const getCreditSummary = (creditKey) => creditReport.value.ctiCreditSummary && creditReport.value.dailyCreditSummary?.creditSummaries?.[0]?.[creditKey] ? creditReport.value.dailyCreditSummary.creditSummaries[0][creditKey] : 0;

const totalUsedCredits = computed(() => formatToSek(getCreditSummary('usedCredits')));
const totalNumberOfCredits = computed(() => formatToAmount(getCreditSummary('numberOfCredits')));
const totalGrantedCredits = computed(() => formatToSek(getCreditSummary('grantedCredits')));
const totalNumberOfCreditors = computed(() => formatToAmount(getCreditSummary('numberOfCreditors')));

const tabs = computed(() => ([
    { id: 'unsecuredLoanTab', name: 'Blanco' },
    { id: 'downPaymentTab', name: 'Avbetalningar' },
    { id: 'creditCardTab', name: 'Kontokredit' },
    { id: 'propertyTab', name: 'Fastighet' },
    { id: 'tenantOwnershipTab', name: 'Bostadsrätt' },
    { id: 'studentLoanTab', name: 'CSN-lån' },
]));

const { tabModel, onBack, onForward, setCurrentTab } = useTabs({
    tabs,
    defaultTabId: tabs.value.length ? tabs.value[0].id : null,
});

const setFirstMatchingTab = () => {
    const creditMapping = { 5: 'unsecuredLoanTab', 4: 'downPaymentTab', 1: 'creditCardTab', 7: 'propertyTab', 9: 'tenantOwnershipTab' };
    const creditTypes = Object.values(creditReport.value?.ctiCreditWithInquirerToCreditTypeMapping || {});

    for (const type of [5, 4, 1, 7, 9]) {
        if (creditTypes.includes(type)) return setCurrentTab(creditMapping[type]);
    }

    setCurrentTab(creditReport.value?.ctiCsnLoan ? 'studentLoanTab' : 'unsecuredLoanTab');
};

onMounted(() => {
    document.title = props.model.name || "Mina tjänster";
    if (!store.state.customerCreditInfoContex.loaded) {
        store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
    }

    setFirstMatchingTab();
});

const getCreditInfo = () => {
    store.dispatch(CUSTOMER_CREDIT_INFO_FETCH);
};
</script>
<style scoped lang="scss">
@import "../../../styles/abstracts/_variables.scss";

.credit-summary-value {
    @media (min-width: 992px) {
        margin-left: 7rem !important;
    }
}

.credit-page-wrapper {
    padding: 0.75rem;
}

.tabs-wrapper {
    border-top: 1px solid $gallery;
}
</style>
