<template>
  <ul v-if="menuLoaded && isLoggedin" class="dashboard-side-bar sidebar menu pl-0"
    data-testid="dashboard-side-bar">
    <li :class="'/mina-tjanster' === router.currentRoute.value.path ? 'active w-100' : 'w-100'">
      <RouterLink class="d-flex" to="/mina-tjanster">
        <span class="input-group-text justify-content-center border-0 bg-transparent color-white pr-0">
          <i class="far fa-home-alt" />
        </span>
        <span class="menu-item-name" data-testid="oversikt-btn">Översikt</span>
      </RouterLink>
    </li>
    <hr />
    <li v-for="(item, index) in purchasedServicesMenu" :key="`dashboard_side_bar_menu_item_${item.dataTestId}_${index}`" class="py-1 px-2 mb-4 w-100"
      :class="(router.currentRoute.value.fullPath.includes(item.baseUrl) ? ' active ' : '')" :data-testid="`dashboard-side-bar-menu-item-${item.dataTestId}-btn`">
      <RouterLink v-if="item.url" :to="item.url" class="d-flex menuCategory">
        <span v-if="item.iconContainer" :class="item.iconContainer" class="input-group-text justify-content-center">
          <i :class="item.icon" class="color-gallery" />
        </span>
        <span v-else class="plain input-group-text justify-content-center">
          <i :class="item.icon" class="color-gallery" />
        </span>
        <span class="menu-item-name">{{ item.title }}</span>
      </RouterLink>
    </li>
    <hr />
    <li class="px-2 w-100" :class="'/mina-installningar' === router.currentRoute.value.path ? 'active' : ''">
      <RouterLink class="d-flex" to="/mina-installningar">
        <span class="input-group-text justify-content-center border-0 bg-transparent color-white pr-0">
          <i class="far fa-cog" />
        </span>
        <span class="menu-item-name">Inställningar</span>
      </RouterLink>
    </li>
  </ul>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed } from 'vue';

const store = useStore();
const router = useRouter();
const isLoggedin = computed(() => store.state.appContext.isLoggedin);
const purchasedServicesMenu = computed(() => store.state.appContext.menu.myServicesMenu.filter((item) => item.isPurchased));
const menuLoaded = computed(() => store.state.appContext.menuLoaded);

</script>
<style lang="scss" scoped>
@import "../../../styles/abstracts/_mixins.scss";
@import "../../../styles/abstracts/_variables.scss";
@import "../../../styles/abstracts/_breakpoints.scss";

.hr-margin {
  margin-top: 26px;
}

.dashboard-side-bar {
  list-style-type: none;
  background-color: $night-rider;
  min-height: 1000px;

  @include breakpoint(max-tablet-portrait) {
    display: none;
  }

  hr {
    border-color: $emperor;
  }

  li {
    position: relative;

    a {
      color: $gallery;

      @include attention() {
        text-decoration: none;
      }

    }

    &:not(.active) {
      @include attention() {
        border-radius: 8px;
        background-color: $emperor-hover;
      }
    }

    .has-notification:after {
      content: "";
      display: inline-block;
      margin-left: 15px;
      width: 8px;
      height: 8px;
      background: $feedback-error;
      border-radius: 50%;
    }

    &.active {
      background-color: $emperor;
      border-radius: 8px;

      >a {
        .menu-item-name {
          text-decoration: none;
        }

        .circle-product {
          background-color: $ocean !important;

          i {
            color: #fff !important;
          }
        }

        i {
          color: $orangegold;
        }
      }
    }
  }

  .sidebar-submenu {
    font-size: 0.875rem;
    list-style-type: none;

    li:first-child {
      margin-top: 1.5rem;
    }

    li:last-child {
      padding-bottom: 0;
    }
  }
}
</style>