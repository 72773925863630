<template>
  <div id="idprotectionpage" class="dashboard" data-testid="dashboard-id-protection-page">
    <div v-if="!customerEventsExpanded" class="row">
      <div class="col-12 mb-2 d-md-none mobile-return-overview-btn">
        <RouterLink to="/mina-tjanster" v-slot="{ href, navigate, isActive }" custom>
          <NdsLink :href="href" :active="isActive" @click="navigate" :prefix="{ prefix: 'far', iconName: 'home-alt' }">
            Översikt
          </NdsLink>
        </RouterLink>
      </div>
      <div class="col-lg-7">
        <div class="mb-3">
          <NdsCard :heading="{ title: 'ID-Skydd', type: 'h3', prefix: { prefix: 'far', iconName: 'user-shield' } }">
            <template #default>
              <div v-if="umbrellaProductId" class="d-flex align-items-baseline mb-3">
                <RouterLink to="/mina-installningar#members-tab" v-slot="{ href, navigate, isActive }" custom>
                  <NdsLink :href="href" :active="isActive" @click="navigate"
                    :prefix="{ prefix: 'far', iconName: 'user' }">
                    Hantera mina medlemmar
                  </NdsLink>
                </RouterLink>
              </div>
              <div v-if="model.importantDocuments !== null" id="idprotection-accordion"
                class="block__enento__accordion">
                <div class="panel-group">
                  <div class="panel-heading">
                    <a class="d-block collapsed m-negative" data-toggle="collapse" href="#idprotection-0"
                      data-testid="accordion-idprotection">
                      <h5 class="font-weight-medium p-3 mb-0">
                        Viktiga dokument för ditt ID-Skydd
                      </h5>
                    </a>
                  </div>
                  <div id="idprotection-0" class="collapse" data-parent="#idprotection-accordion">
                    <div class="enento-tinymce panel-body py-3">
                      <NdsLink v-for="(document, index) in model.importantDocuments.value"
                        :key="`id_protection_link_${index}`" :href="document.href" target="_blank"
                        :prefix="{ prefix: 'far', iconName: 'file-pdf' }">
                        {{ document.text }}
                      </NdsLink>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </NdsCard>
        </div>
        <div class="mb-3">
          <NdsCard :heading="{ title: 'UC Bevakning', type: 'h3', prefix: { prefix: 'far', iconName: 'eye' } }">
            <template #default>
              <CustomerEvents :items-per-page="5" :paging-enabled="false" :search-enabled="false"
                :showMoreButton="!customerEventsExpanded" />
            </template>
          </NdsCard>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="mb-3">
          <NdsCard
            :heading="{ title: 'Blivit ID-kapad?', type: 'h3', prefix: { prefix: 'far', iconName: 'hand-paper' } }">
            <template #default>
              <p class="color-emperor mb-2">
                Vi hjälper vi dig dygnet runt via vår assistans. Du når assistansen på telefonnumret
              </p>
              <a href="tel:0771-20 25 20" class="text-decoration-none text-center">
                <p class="font-weight-medium phone-large mb-0">0771-20 25 20</p>
              </a>
            </template>
          </NdsCard>
        </div>
        <div class="mb-3">
          <NdsCard v-if="onlineMonitoringModel"
            :heading="{ title: 'Onlinebevakning', type: 'h3', prefix: { prefix: 'far', iconName: 'globe' } }">
            <template #default>
              <p v-if="model.onlinesurveillanceText.value" v-html="model.onlinesurveillanceText.value" />
              <OnlineMonitoring :model="onlineMonitoringModel" />
            </template>
          </NdsCard>
        </div>
        <div class="mb-3">
          <NdsCard v-if="model.insuranceInfoText"
            :heading="{ title: 'Din försäkring', type: 'h3', prefix: { prefix: 'far', iconName: 'life-ring' } }">
            <template #default>
              <p v-if="model.insuranceInfoText.value" v-html="model.insuranceInfoText.value" />
            </template>
          </NdsCard>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-lg-9 mb-3">
        <NdsIconButton theme="enento" variant="text" prefix="far" iconName="arrow-left"
          @click="onToggleExpandCustomerEvents" aria-label="Tillbaka till ID-Skydd" />
        <NdsCard :heading="{ title: 'UC Bevakning', type: 'h3', prefix: { prefix: 'far', iconName: 'eye' } }">
          <template #default>
            <CustomerEvents :items-per-page="8" :paging-enabled="true" :search-enabled="true"
              :showMoreButton="!customerEventsExpanded" />
          </template>
        </NdsCard>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, ref, onMounted } from 'vue';
import { NdsLink, NdsCard, NdsIconButton } from '@nds/vue';
import CustomerEvents from '../components/CustomerEvents.vue';
import OnlineMonitoring from '../components/OnlineMonitoring.vue';

const props = defineProps({
  model: Object,
  smallTitle: Boolean
});
const store = useStore();
const router = useRouter();

const onlineMonitoringModel = computed(() => props.model.mainContentArea.expandedValue.find((item) => item.contentType.includes("DataMonitorBlock")));
const umbrellaProductId = computed(() => store.state.customerInfoContex.customerEssentials.customerProducts.umbrellaProductId);
const customerEventsExpanded = ref(false);

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  if (router.currentRoute.value.query.all) {
    customerEventsExpanded.value = true;
  }
});

const onToggleExpandCustomerEvents = () => {
  router.push({ query: undefined });
  customerEventsExpanded.value = !customerEventsExpanded.value;
}
</script>
<style scoped lang="scss">
@import "../../../styles/abstracts/_breakpoints.scss";

.phone-large {
  font-family: Gilroy-Bold;
  font-size: 2em;
  line-height: 2.5rem;

  @include breakpoint(max-phone) {
    font-size: 1.75em;
    line-height: 2rem;
  }
}

.block__enento__accordion .panel-group {
  border-bottom: none;

  .panel-heading a {

    &.collapsed:hover h5 {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:after {
      right: 0;
    }
  }
}
</style>