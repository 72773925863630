<template>
  <div class="dashboard" data-testid="dashboard-my-settings-page">
    <div id="mysettings-page">
      <div class="mb-2 d-md-none mobile-return-overview-btn">
        <RouterLink to="/mina-tjanster" v-slot="{ href, navigate, isActive }" custom>
          <NdsLink :href="href" :active="isActive" @click="navigate" :prefix="{ prefix: 'far', iconName: 'home-alt' }">
            Översikt
          </NdsLink>
        </RouterLink>
      </div>
      <NdsCard tight :heading="{ title: model.name, type: 'h3', prefix: { prefix: 'far', iconName: 'cog' } }">
        <template #default>
          <NdsTabs v-model="tabModel" :tabs="tabs" @back="onBack" @forward="onForward">
            <template #tab1>
              <div data-testid="dashboard-my-settings-my-information-tab">
                <form class="row">
                  <div class="col-md-6 form-group order-1">
                    <label class="disabled" for="name">{{ helperTexts.nameLabel }}</label>
                    <input id="name" type="text" class="form-control disabled" disabled
                      :value="customerEssentials.customerFullName">
                  </div>
                  <div class="col-md-6 form-group order-3 order-md-2">
                    <label for="email">{{ helperTexts.emailLabel }}</label>
                    <input id="email" v-model="form.customerEmail" type="text" class="form-control"
                      :class="v$.customerEmail.$error ? 'is-invalid' : ''" :placeholder="helperTexts.placeholderEmail"
                      @keyup="onDataChange">
                    <small v-if="v$.customerEmail.email.$invalid" class="is-invalid">
                      {{ v$.customerEmail.email.$message }}
                    </small>
                    <small v-else-if="v$.customerEmail.required.$invalid" class="is-invalid">
                      {{ v$.customerEmail.required.$message }}
                    </small>
                  </div>
                  <div class="col-md-6 form-group order-2 order-md-3">
                    <label class="disabled" for="personId">{{ helperTexts.ssnLabel }}</label>
                    <input id="personId" type="text" class="form-control disabled" :placeholder="helperTexts.placeholderSSN" disabled
                      :value="customerEssentials.customerSocSecNo">
                  </div>
                  <div class="col-md-6 form-group order-4">
                    <label for="phone">{{ helperTexts.phoneLabel }}</label>
                    <input id="phone" v-model="form.customerPhone" type="text" class="form-control"
                      :placeholder="helperTexts.placeholderPhone" :class="v$.customerPhone.$error ? 'is-invalid' : ''"
                      @keyup="onDataChange">
                    <small v-if="v$.customerPhone.valid_cellphone.$invalid" class="is-invalid">
                      {{ v$.customerPhone.valid_cellphone.$message }}
                    </small>
                    <small v-else-if="v$.customerPhone.required.$invalid" class="is-invalid">
                      {{ v$.customerPhone.required.$message }}
                    </small>
                  </div>
                  <div class="col-12 order-last">
                    <hr class="pb-3">
                    <NdsHeading title="För dig med ID-Skydd" type="h4" tight />
                    <NdsText type="p" tight text="Hur vill du bli meddelad om förändringar i din kreditupplysning?" />
                    <div class="form-check mb-3">
                      <input id="smsCheck" v-model="form.notificationBySms" type="checkbox" class="form-check-input"
                        @change="onDataChange">
                      <label class="form-check-label" for="smsCheck">{{ helperTexts.smsLabel }}</label>
                    </div>
                    <div class="form-check mb-4">
                      <input id="emailCheck" v-model="form.notificationByMail" type="checkbox" class="form-check-input"
                        @change="onDataChange">
                      <label class="form-check-label" for="emailCheck">{{ helperTexts.emailLabel }}</label>
                    </div>
                    <hr class="pb-3">
                    <NdsHeading title="Vill du ha vårt nyhetsbrev?" type="h4" tight />
                    <NdsText type="p" tight text="Vill du få de senaste nyheterna, tips och intressanta artiklar kring ekonomi från
                    oss på UC? Då är
                    vårt nyhetsbrev perfekt för dig!" />
                    <div class="form-check mb-5">
                      <input id="newsletterCheck" v-model="form.newsLetterSubscription" type="checkbox"
                        class="form-check-input" @change="onDataChange">
                      <label class="form-check-label" for="newsletterCheck">Ja, tack!</label>
                    </div>
                    <button :disabled="!dataChanged || customerEssentialsPending" type="button"
                      class="submit-button btn btn-enento-blue btn-lg btn-wide w-100-md mb-3"
                      @click="submitCustomerSettings">
                      <div v-if="customerEssentialsPending && hasSubmitted" class="submit-button-content">
                        <span>Laddar...</span>
                        <NdsLoader size="25" />
                      </div>
                      <div v-else>
                        Spara
                      </div>
                    </button>
                    <Alert v-if="showSavedAlert" type="success" title="Sparat!" description="Dina ändringar är sparade."
                      @close="onCloseAlert" />
                    <Alert v-if="showFailedAlert" type="error" title="Något gick fel!"
                      description="Vänligen ladda om sidan och försök igen." @close="onCloseAlert" />
                  </div>
                </form>
              </div>
            </template>
            <template #tab2>
              <div data-testid="dashboard-my-settings-payment-tab">
                <NdsText type="p" tight text="Här kan du se över dina tjänster och betalningsuppgifter" />
                <div v-if="customerEssentialsPending" class="align-items-center justify-content-center d-flex">
                  <NdsLoader />
                </div>
                <template v-else-if="recurringProducts.length > 0">
                  <template v-for="(product, index) in recurringProducts">
                    <div v-if="product.productPackage && product.productPackage.name.toLowerCase() !== 'kreddy'"
                      :key="`product_package_${index}`">
                      <NdsHeading :title="product.productPackage.name" type="h4" tight />
                      <div class="border-gray mb-4">
                        <table class="table mt-0 mb-0">
                          <thead>
                            <tr class="border-bottom-black">
                              <td>
                                <h6 class="mb-0">
                                  Pris
                                </h6>
                              </td>
                              <td>
                                <h6 class="mb-0">
                                  Nästa betalning
                                </h6>
                              </td>
                              <td>
                                <h6 class="mb-0">
                                  Betalsätt
                                </h6>
                              </td>
                              <td>
                                <h6 class="mb-0">
                                  Fler val
                                </h6>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr :data-testid="`settings-table-row-${product.productPackage.id}`">
                              <td data-testid="settings-table-price-txt">
                                {{ product.price }} kr/mån
                              </td>
                              <td v-if="product.paymentType === 'UMBRELLA'" />
                              <td v-else-if="product.endDate && !product.isCancellable">
                                <p style="margin-bottom:0;" data-testid="settings-table-terminate-date-txt">
                                  Avslutas
                                </p>
                                {{ formatDate(product.endDate) }}
                              </td>
                              <td v-else data-testid="settings-table-nextpayment-txt">
                                {{ formatDate(product.nextPurchaseDate) }}
                              </td>
                              <td data-testid="settings-table-paymnettype-txt">
                                {{ translatePaymentType(product.paymentType) }}
                              </td>
                              <td v-if="product.paymentType === 'INVOICE'" class="d-none d-lg-table-cell"
                                style="width:25%;">
                                <NdsLink href="https://customer.horizonafs.com/SE/uc" target="_blank"
                                  :prefix="{ prefix: 'far', iconName: 'external-link' }">
                                  Gå till fakturaportal
                                </NdsLink>
                              </td>
                              <td v-else-if="product.paymentType === 'CARD' && product.paymentType != 'RESELLER'"
                                class="d-none d-lg-table-cell" style="width:25%;">
                                <NdsButton @click="onChangeCard($event, product)" size="small" variant="text">
                                  Byt kort
                                </NdsButton>
                              </td>
                              <td class="d-none d-lg-table-cell" style="width:10%;">
                                <NdsLink target="_blank"
                                  :href="`/minuc/betalningar/createreceiptpdf?pId=${product.productPackage.id}&pDate=${product.purchasedDate}&orderId=${product.id}`"
                                  data-testid="settings-table-receipt-btn">
                                  Kvitto
                                </NdsLink>
                              </td>
                              <td style="width:20%" class="d-none d-lg-table-cell">
                                <NdsButton @click="onTerminateProductClicked($event, product)" size="small"
                                  variant="text"
                                  :disabled="canTerminateProduct({ product, isPendingRef: terminateProductPending })">
                                  Avsluta tjänst
                                </NdsButton>
                              </td>
                              <td style="width:25%" class="d-lg-none">
                                <PaymentOptionsPopover :product="product" @on-change-card="onChangeCard(null, product)"
                                  @on-terminate-product="onTerminateProductClicked(null, product)" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </template>
                </template>
                <div v-else>
                  <NdsHeading title="Du har inga abonnemang" type="h4" tight />
                </div>
                <NdsHeading title="Information om betalningar" type="h3" tight />
                <div id="mysettings-accordion" class="block__enento__accordion">
                  <div class="panel-group">
                    <div v-for="(faqBlock, index) in model.faqArea.expandedValue"
                      :key="`my_setting_info_about_payment_${index}`">
                      <div class="panel-heading">
                        <a class="collapsed" data-toggle="collapse" :href="'#faq-' + index"
                          :data-testid="'accordion-heading-' + faqBlock.name">
                          <h5 class="font-weight-medium p-3 mb-0">
                            {{ faqBlock.name }}
                          </h5>
                        </a>
                      </div>
                      <div :id="'faq-' + index" class="collapse" data-parent="#mysettings-accordion">
                        <div class="enento-tinymce panel-body px-3 my-4">
                          <p v-html="faqBlock.text.value" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="ownedUmbrellaProductId" #tab3>
              <div data-testid="dashboard-my-settings-members-tab">
                <GroupInvite :group-id="ownedUmbrellaProductId"  />
              </div>
            </template>
          </NdsTabs>
        </template>
      </NdsCard>
    </div>
  </div>
  <NdsConfirmModal v-bind="changeCardModal.props" v-model="changeCardModal.isOpen.value"
    @onConfirm="onCloseCardModalOrRetry" @onCancel="changeCardModal.closeModal"
    @onDismiss="changeCardModal.dismissModal">
    <template #body>
      <div id="checkout-container-div" />
    </template>
  </NdsConfirmModal>
  <NdsConfirmModal v-bind="terminateProductModal.props" v-model="terminateProductModal.isOpen.value"
    @onConfirm="onTerminateProduct" @onCancel="terminateProductModal.closeModal"
    @onDismiss="terminateProductModal.dismissModal">
    <template #body>
      <p data-testid="terminatepopup-txt">
        Är du säker på att du vill avsluta <b>{{ selectedProduct.product.productPackage.name }}</b>?
        <br>
        <br>
        Abonnemanget löper i så fall ut
        <b data-testid="terminatepopup-terminate-date">{{ formatDate(selectedProduct.product.endDate) }}</b>, och ingen
        ny betalning genomförs.
      </p>
    </template>
  </NdsConfirmModal>
  <NdsConfirmModal v-bind="recentlyTerminatedProductModal.props" v-model="recentlyTerminatedProductModal.isOpen.value"
    @onConfirm="recentlyTerminatedProductModal.closeModal" @onCancel="recentlyTerminatedProductModal.closeModal"
    @onDismiss="recentlyTerminatedProductModal.dismissModal" />
  <NdsConfirmModal v-bind="errorModal.props" v-model="errorModal.isOpen.value" @onConfirm="errorModal.closeModal"
    @onCancel="errorModal.closeModal" @onDismiss="errorModal.dismissModal" />
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, reactive, ref, onMounted, watch, nextTick } from 'vue';
import { NdsLink, NdsButton, NdsHeading, NdsLoader, NdsCard, NdsTabs, NdsConfirmModal, NdsText, useConfirmModal, useTabs } from '@nds/vue';
import { useRouter } from 'vue-router';
import { useVuelidate } from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import Alert from '../components/Alert.vue';
import PaymentOptionsPopover from '../components/PaymentOptionsPopover.vue';
import GroupInvite from '../components/GroupInvite.vue';

import { FormatDate } from '../_helpers.js';
import { isValidSwedishCellPhone } from '../utils/validators.js';
import {
  CUSTOMER_INFO_FETCH,
  CUSTOMER_INFO_POST_SETTINGS,
  CUSTOMER_INFO_POST_TERMINATE_PRODUCT,
} from '../Scripts/store/modules/customerInfoContex';
import { UMBRELLA_MEMBERS_FETCH } from '../Scripts/store/modules/umbrellaMembersContext';
import { postNeteasyChangeCardPaymentId } from '../Scripts/providers/customerApiProvider';
import { defaultGenericError, serviceErrorText } from '../components/modals/modalsUtils';
import { useEbbot } from '../utils/ebbotUtils.js';
import { getRequestVerificationToken } from '../utils/authUtils';
import { canTerminateProduct } from '../utils/productUtils';
import helperTexts from '../../../json/helperTexts.json';

const props = defineProps(['model']);
const store = useStore();
const customerEssentials = computed(() => store.state.customerInfoContex.customerEssentials);
const customerEssentialsLoaded = computed(() => store.state.customerInfoContex.loaded);
const customerEssentialsFailed = computed(() => store.state.customerInfoContex.failed);
const customerEssentialsPending = computed(() => store.state.customerInfoContex.pending);
const ownedUmbrellaProductId = computed(() => store.state.customerInfoContex.customerEssentials.customerProducts.ownedUmbrellaProductId);
const recurringProducts = computed(() => store.state.customerInfoContex.customerEssentials.customerProducts?.recurringProducts);
const terminateProductHasError = computed(() => store.state.customerInfoContex.failed);
const hasRecentlyPayedInvoiceForTerminatedProduct = computed(() => store.state.customerInfoContex.hasRecentlyPayedInvoiceForTerminatedProduct);
const terminateProductPending = computed(() => store.state.customerInfoContex.pending);
const dataChanged = ref(false);
const selectedProduct = reactive({ product: null });
const showSavedAlert = ref(false);
const showFailedAlert = ref(false);
const hasSubmitted = ref(false);

const { showEbbot, hideEbbot } = useEbbot();

const changeCardModal = useConfirmModal({
  title: 'Byt kort',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "credit-card" },
  confirmBtnLabel: "Stäng",
  testId: 'change-card-modal',
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const terminateProductModal = useConfirmModal({
  title: 'Avsluta abonnemang',
  variant: 'warning',
  prefix: { prefix: "far", iconName: "exclamation-triangle" },
  cancelBtnLabel: "Avbryt",
  confirmBtnLabel: "Avsluta abonnemang",
  testId: 'terminate-product-subscription-modal',
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const recentlyTerminatedProductModal = useConfirmModal({
  title: 'Avsluta abonnemang',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "check-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: "Tråkigt att du vill avsluta din tjänst hos minUC. </br></br> Om du nyligen fått en årsfaktura så är du välkommen att kontakta oss på kundservice via telefon så hjälper vi dig makulera den. Våran kundtjänst har öppet <strong>måndag till fredag kl 10:00-17:00</strong></br></br>Telefon <a href='tel:0771-20 22 77'>0771-20 22 77</a>",
    isRichText: true,
  },
  testId: 'recently-terminated-product-modal',
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const errorModal = useConfirmModal({
  ...defaultGenericError,
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

const form = reactive({
  customerEmail: customerEssentials.value.customerEmail,
  customerPhone: customerEssentials.value.customerPhone,
  notificationBySms: customerEssentials.value.notificationBySms,
  notificationByMail: customerEssentials.value.notificationByMail,
  newsLetterSubscription: customerEssentials.value.newsLetterSubscription,
});

const rules = computed(() => {
  return {
    customerEmail: {
      required: helpers.withMessage('Vänligen fyll i detta fält', required),
      email: helpers.withMessage('Vänligen fyll i en giltig e-postadress', email)
    },
    customerPhone: {
      valid_cellphone: {
        $validator: isValidSwedishCellPhone,
        $message: "Vänligen fyll i ett giltigt mobilnummer"
      },
      required: helpers.withMessage('Vänligen fyll i detta fält', required)
    },
  }
});

const v$ = useVuelidate(rules, form);
const router = useRouter();
const tabs = computed(() => ([
  { id: 'tab1', name: 'Mina uppgifter' },
  { id: 'tab2', name: 'Betalningar' },
  ownedUmbrellaProductId.value && { id: 'tab3', name: 'Medlemmar' },
].filter(Boolean)));
const { tabModel, onBack, onForward, setCurrentTab } = useTabs({
  tabs,
  defaultTabId: "tab1",
});

onMounted(() => {
  document.title = props.model.name || "Mina tjänster";
  store.dispatch(CUSTOMER_INFO_FETCH, { reFetch: false, checkCancellableProducts: true });
  if (store.state.customerInfoContex.loaded) {
    form.customerEmail = customerEssentials.value.customerEmail;
    form.customerPhone = customerEssentials.value.customerPhone;
    form.notificationBySms = customerEssentials.value.notificationBySms;
    form.notificationByMail = customerEssentials.value.notificationByMail;
    form.newsLetterSubscription = customerEssentials.value.newsLetterSubscription;
  }
  if (!store.state.umbrellaMembersContext.loaded && store.state.customerInfoContex.customerEssentials.customerProducts.umbrellaProductId) {
    store.dispatch(UMBRELLA_MEMBERS_FETCH, { umbrellaProductId: store.state.customerInfoContex.customerEssentials.customerProducts.umbrellaProductId });
  }
  if (router.currentRoute.value.hash === '#payments-tab') {
    setCurrentTab('tab2');
  }
});

watch(terminateProductHasError, (value) => {
  if (value) {
    errorModal.openModal();
  }
})

watch(customerEssentialsFailed, () => {
  if (customerEssentialsFailed.value && hasSubmitted.value) {
    showSavedAlert.value = false;
    showFailedAlert.value = true;
  }
})

watch(hasRecentlyPayedInvoiceForTerminatedProduct, () => {
  if (hasRecentlyPayedInvoiceForTerminatedProduct.value) {
    recentlyTerminatedProductModal.openModal();
  }
});

watch(customerEssentialsLoaded, () => {
  if (customerEssentialsLoaded.value && hasSubmitted.value) {
    form.customerEmail = customerEssentials.value.customerEmail;
    form.customerPhone = customerEssentials.value.customerPhone;
    form.notificationBySms = customerEssentials.value.notificationBySms;
    form.notificationByMail = customerEssentials.value.notificationByMail;
    form.newsLetterSubscription = customerEssentials.value.newsLetterSubscription;

    dataChanged.value = false;

    showSavedAlert.value = true;
    showFailedAlert.value = false;
  }
})

const onCloseAlert = () => {
  showSavedAlert.value = false;
  showFailedAlert.value = false;
}

const formatDate = (timestamp) => {
  if (timestamp == null) {
    return '';
  }
  return FormatDate(parseInt(timestamp));
};

const translatePaymentType = (paymentType) => {
  switch (paymentType) {
    case "INVOICE":
      return "Faktura";
    case "CARD":
      return "Kort";
    case "RESELLER":
      return "Återförsäljare";
    case "UMBRELLA":
      return "Medlem";
    default:
      return paymentType;
  }
};

const onTerminateProductClicked = (e, product) => {
  e?.preventDefault();
  selectedProduct.product = product;
  nextTick(() => {
    setTimeout(() => {
      terminateProductModal.openModal();
    }, 200);
  });
};

const onTerminateProduct = () => {
  store.dispatch(CUSTOMER_INFO_POST_TERMINATE_PRODUCT, {
    pppid: selectedProduct.product?.productPackage?.id,
    hasRecentlyPayedInvoice: selectedProduct.product?.productPackage?.recurringPeriodInMonth === 12
  });
  terminateProductModal.closeModal();
};

const onDataChange = () => {
  if (!v$.value.$error) {
    dataChanged.value = true;
  } else {
    dataChanged.value = false;
  }
};
const submitCustomerSettings = () => {
  const csrfToken = getRequestVerificationToken();
  showSavedAlert.value = false;
  showFailedAlert.value = false;
  hasSubmitted.value = true;
  store.dispatch(CUSTOMER_INFO_POST_SETTINGS, {
    csrfToken,
    email: form.customerEmail,
    phoneNumber: form.customerPhone,
    smsNotification: form.notificationBySms,
    newsLetterSubscription: form.newsLetterSubscription,
    mailNotification: form.notificationByMail,
  });
};

const onCloseCardModalOrRetry = () => {
  if (changeCardModal.props.state.status === 'error') {
    return changeCardModal.setState({
      status: 'default',
    });
  }
  return changeCardModal.closeModal();
}

const onChangeCard = (e, product) => {
  e?.preventDefault();
  selectedProduct.product = product;
  changeCardModal.setState({
    status: 'pending',
  });
  nextTick(() => {
    setTimeout(() => {
      changeCardModal.openModal();
    }, 500);
  });

  const pppid = selectedProduct.product?.id;
  postNeteasyChangeCardPaymentId({ pppid }).then(response => {
    const paymentId = response.data.paymentId;
    changeCardModal.setState({
      status: 'default',
    });
    const checkoutKey = document.getElementById("netscheckoutkey").value;
    nextTick(() => {
      setTimeout(() => {
        if (paymentId && checkoutKey) {
          const checkoutOptions = {
            checkoutKey,
            paymentId,
            containerId: "checkout-container-div",
            language: "sv-SE"
          };
          // eslint-disable-next-line
          const checkout = new Dibs.Checkout(checkoutOptions);
          checkout.on('payment-completed', () => {
            return changeCardModal.setState({
              status: 'complete',
              message: `Du har bytt kort för <strong>${selectedProduct.product?.productPackage?.name}</strong> med order id: <strong>${selectedProduct.product?.id}</strong>`,
            });
          });
        } else {
          return changeCardModal.setState({
            status: 'error',
            message: serviceErrorText,
          });
        }
      }, 1000);
    });
  }).catch(() => {
    return changeCardModal.setState({
      status: 'error',
      message: serviceErrorText,
    });
  });
};
</script>
<style lang="scss" scoped>
.submit-button {
  .submit-button-content {
    display: inline-flex;
    align-items: center;
  }
}
</style>
<style lang="scss" scoped>
@import "../../../styles/abstracts/_variables.scss";
@import "../../../styles/abstracts/_breakpoints.scss";

#mysettings-page .form-group label {
  color: #333333;
  font-size: 14px;
  font-weight: 700;
}

.alert-dismissible {
  padding-left: 65px;
}

.hide {
  display: none;
}

input {
  border-color: $silver;
}

table {
  tr:hover td {
    background: inherit;
    color: inherit;
  }

  td {
    border-top: 0;
    padding: 11px 16px;
    font-size: 14px;
    vertical-align: middle;

    @include breakpoint(max-tablet-portrait) {
      font-size: 12px;
    }
  }

  tbody tr {
    background-color: $light-smoke;

    td:first-child {
      width: 18%;

      @include breakpoint(max-tablet-portrait) {
        width: auto;
      }
    }

    td:nth-child(2) {
      width: 20%;

      @include breakpoint(max-tablet-portrait) {
        width: auto;
      }
    }

    td:last-child {
      width: 10%;

      @include breakpoint(max-tablet-portrait) {
        width: auto;
      }
    }
  }
}
</style>
