<template>
  <NdsCard :heading="{ title: 'CSN-lån', type: 'h3', prefix: { prefix: 'far', iconName: 'graduation-cap' } }"
    testId="minupplysning-mobile-csn-tab">
    <template #controls>
      <NdsIconButton theme="enento" variant="text" prefix="far" iconName="question-circle" @click="infoModal.openModal"
        aria-label="Kreditengagemang förklaring" />
    </template>
    <template #default>
      <CsnMobile v-if="report.creditReport.ctiCsnLoan" :cti-csn-loan="report.creditReport.ctiCsnLoan" />
      <ZeroState v-else icon="far fa-graduation-cap" text="Inget CSN-lån registrerat" />
      <NdsFooter class="mt-2" :prefix="{ prefix: 'far', iconName: 'book' }" text="Källa: CSN" tight />
    </template>
  </NdsCard>
  <NdsConfirmModal v-bind="infoModal.props" v-model="infoModal.isOpen.value" @onConfirm="infoModal.closeModal"
    @onCancel="infoModal.closeModal" @onDismiss="infoModal.dismissModal" />
</template>
<script setup>
import { NdsIconButton, NdsCard, NdsConfirmModal, NdsFooter, useConfirmModal } from '@nds/vue';
import CsnMobile from '../../components/CsnMobile.vue';
import ZeroState from '../../components/myinformation/ZeroState.vue';
import { useEbbot } from '../../utils/ebbotUtils.js';

const props = defineProps({
  report: Object,
  introText: String
});

const { showEbbot, hideEbbot } = useEbbot();

const infoModal = useConfirmModal({
  title: 'CSN-lån',
  variant: 'primary',
  prefix: { prefix: "far", iconName: "question-circle" },
  confirmBtnLabel: "Stäng",
  body: {
    text: props.introText,
    isRichText: true,
  },
  onOpenCallback: hideEbbot,
  onDismissCallback: showEbbot,
  onCloseCallback: showEbbot,
});

</script>