export const defaultGenericError = {
    title: 'Ett fel har inträffat!',
    body: {
        text: '<p>Ett tekniskt fel har inträffat, vänligen kontakta kundservice så hjälper vi dig!</br></br><a class="enento__link" href="/kundservice">Kontakta oss</a></p>',
        isRichText: true,
    },
    prefix: { prefix: "far", iconName: "exclamation-triangle" },
    confirmBtnLabel: "Stäng",
};

export const creditReportError = {
    title: 'Ett fel har inträffat!',
    body: {
        text: '<p>Tjänsten är för närvarande inte tillgänglig. Vänligen kontakta kundservice på telefon <a class="enento__link" href="tel:0771-20 22 77">0771-20 22 77</a> eller skicka ett e-post till <a class="enento__link" href="mailto:minuc@uc.se">minuc@uc.se</a>. Ange kod 500.</p><p>&nbsp;</p><p>Kundservice öppettider:</p><p>mån - fre 10:00 - 15:00</p>',
        isRichText: true,
    },
    prefix: { prefix: "far", iconName: "exclamation-triangle" },
    confirmBtnLabel: "Stäng",
};

export const createPdfErrorText = '<p>PDF:en kunde inte skapas, vänligen försök igen. </br></br>Om felet kvarstår kontakta kundservice på telefon <a class="enento__link" href="tel:0771-20 22 77">0771-20 22 77</a> eller skicka ett e-post till <a class="enento__link" href="mailto:minuc@uc.se">minuc@uc.se</a>.</br></p><p>Kundservice öppettider:</p><p>mån - fre 10:00 - 15:00</p>';

export const serviceErrorText = '<p>Tjänsten är för närvarande inte tillgänglig, vänligen försök igen. </br></br>Om felet kvarstår kontakta kundservice på telefon <a class="enento__link" href="tel:0771-20 22 77">0771-20 22 77</a> eller skicka ett e-post till <a class="enento__link" href="mailto:minuc@uc.se">minuc@uc.se</a>.</br></p><p>Kundservice öppettider:</p><p>mån - fre 10:00 - 15:00</p>';
